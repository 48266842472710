import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum-slim";
import useRoutePattern from "modules/hooks/useRoutePattern";
import { useLocation } from "react-router-dom";

// ProtectedRoute handles its own logging, but for routes that we want
// logging/metrics from but don't want to enforce login, use this
const LoggedRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const routePattern = useRoutePattern();

  useEffect(() => {
    datadogRum.startView({ name: routePattern });
  }, [location.pathname]);

  return children;
};

export default LoggedRoute;
