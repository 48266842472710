// Shamelessly lifted from: https://stackoverflow.com/a/76343472
// If we have issues with this, can try: https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#react-router-instrumentation

import { useLocation, useParams } from "react-router-dom";

// a path looks like: /resources/explorer/us/aeby_test/collections/4th_version_collection_that_needs_aliased
// a route pattern looks like: /resources/explorer/:region/:db/:resource/:name

// we primarily need route patterns for logging purposes
const useRoutePattern: any = () => {
  let { pathname } = useLocation();
  const params = useParams();
  return Object.entries(params).reduce((path: string, [key, value]) => {
    if (value && path.includes(`/${encodeURIComponent(value)}`))
      return path.replace(`/${encodeURIComponent(value)}`, `/:${key}`);
    else return path.replace(`/${value}`, `/:${key}`);
  }, pathname);
};

export default useRoutePattern;
