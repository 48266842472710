import useSWR from "swr";
import { getInvoices } from "modules/api/auth-service/billing";
import { usePollInvoicesStore } from "modules/stores/PollInvoicesStore";

const useInvoices = (revalidateOnFocus = false) => {
  const pollInvoices = usePollInvoicesStore((state) => state.pollInvoices);
  const swr = useSWR("invoices", getInvoices, {
    refreshInterval: pollInvoices ? 5_000 : undefined,
    revalidateOnFocus,
  });
  return swr;
};

export default useInvoices;
