import useSWR from "swr";
import { getBillingInfo } from "../api/auth-service/billing";
import {
  FREE_USAGE_PLANS,
  TEAM_USAGE_PLANS,
  UPGRADEABLE_USAGE_PLANS,
} from "modules/api/auth-service/billing";

const useBillingInfo = () => {
  const key = "billing_info";

  const billingInfo = useSWR(key, getBillingInfo);

  const currentPlan = billingInfo.data?.plans.usage;

  const hasTeamAccess = TEAM_USAGE_PLANS.includes(currentPlan);
  const allowUpgrade = UPGRADEABLE_USAGE_PLANS.includes(currentPlan);
  const isFreePlan = FREE_USAGE_PLANS.includes(currentPlan);

  return {
    currentPlan,
    hasTeamAccess,
    allowUpgrade,
    isFreePlan,
    billingInfo,
  };
};

export default useBillingInfo;
