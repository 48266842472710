import { useMemo } from "react";
import { type Params, useLocation, useParams } from "react-router-dom";

export type FaunaURLParams = {
  path?: string;
  encodedPath?: string;
  regionPrefix?: string;
  collectionId?: string;
  documentId?: string;
  functionId?: string;
  roleId?: string;
  providerId?: string;
  token?: string;
  strategyName?: string;
  urlQuery?: URLSearchParams;
  inviteToken?: string;
};

// Hook that puts usage of useParams in one place, helps with any customization we
//  need to perform on the url params
export const useFaunaUrl = (): FaunaURLParams => {
  const urlParams: Readonly<Params<string>> = useParams();
  const { search } = useLocation();
  // expose query parameters
  const urlQuery = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
  const { "*": encodedPath, path = decodeURI(encodedPath || "") } = urlParams;
  return {
    ...urlParams,
    encodedPath,
    regionPrefix: urlParams.region,
    path,
    urlQuery,
  };
};
