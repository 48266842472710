import { Client, ClientConfiguration, fql, Query, QueryValue } from "fauna";
import { Region } from "../../region";
import { AuthScope, getScopedSecret, Scope } from "./secret";

import hash from "object-hash";
import { DatabasePath } from "modules/utils/path";
import { ValueFormat } from "fauna/dist/wire-protocol";
import { useQuery } from "./hooks/useQuery";
import { PAGE_SIZES } from "constants/index";

/** The default page size to fetch per service call. */
export const DEFAULT_PAGE_SIZE = PAGE_SIZES.DEFAULT.serverPageSize;

/** The default page size to display to the user. */
export const DEFAULT_PAGE_DISPLAY_SIZE = PAGE_SIZES.DEFAULT.clientPageSize;

/**
 * When size is set this determines type of the `options` object passed to `q.Paginate()`.
 * When dropTake is set it will use a query like fql`theQuery().drop(drop).take(take)`
 * We will be replacing all use of paginate() with take drop as paginate() reflects a
 * snapshot of user data and is not suitable for usage in a real-time application.
 */
export interface PaginateOptions {
  size?: number;
  dropTake?: {
    drop: number;
    take: number;
  };
}

// Custom map interface to enforce non-undefined get return type
interface ClientMap extends Map<string, Client> {
  get(key: string): Client;
}

/** Additional configuration for the underlying `faunadb.Client`. */
export type APIConfig = Omit<
  Partial<ClientConfiguration>,
  "secret" | "scheme" | "domain" | "port"
>;

/**
 * Either a secret and endpoint or a
 * {@link DatabasePath}.
 */
export type ClientSpec =
  | {
      secret: string;
      endpoint: URL;
    }
  | DatabasePath;

const isDatabasePath = (clientSpec: any): clientSpec is DatabasePath =>
  "regionGroup" in clientSpec;

/** A connection to an instance of Fauna. */
export class FaunaAPI {
  /** The region group of the Fauna instance. */
  readonly region: Region;

  /** The URL of the Fauna instance. */
  readonly endpoint: URL;

  /** The maximum number of connections to a make to Fauna. */
  readonly maxConns: number;

  /** The scope (subdatabase and role) that this API uses to connect to Fauna. */
  readonly scope?: Scope;

  /** Type of fauna client to use */
  readonly clientFormat?: ValueFormat;

  /** The configuration used to create the underlying `faunadb` client. */
  readonly clientConfig: Partial<ClientConfiguration>;

  /** The underlying `faunadb` client used to make the connection and run queries. */
  readonly client: Client;

  /** The path of this database, relative to the Region root tenant db. */
  get dbPath() {
    return this.scope?.dbPath ?? "";
  }

  /** Path of this database inside a DatabasePath object for convenience */
  get databasePath() {
    return new DatabasePath(
      this.dbPath === "" ? [] : this.dbPath.split("/"), // avoid passing [""] if the db path is an empty string
      this.region
    );
  }

  /** Whether this database is the root tenant DB of its region group. */
  get isRoot() {
    return this.dbPath === "";
  }

  /** The secret used by this API (including scope). */
  get secret() {
    return this.clientConfig.secret;
  }

  /**
   * A unique key for the query, suitable for use as a key in SWR.
   * @param expr if provided, the query expression to get the cache key for.
   * @param tag if provided, the tag to associate with the cache key. You can
   * bust all keys associated with a tag.
   */
  cacheKey(expr?: Query, tag?: string) {
    const key = hash([
      this.endpoint,
      this.secret,
      this.clientConfig.format,
      this.clientConfig.linearized,
      this.clientConfig.typecheck,
      expr ? JSON.stringify(expr.encode()) : undefined,
    ]);
    if (tag !== undefined) {
      return [tag, key];
    }
    return key;
  }

  cachePath(path: string) {
    return hash([this.endpoint, this.secret, path]);
  }

  /**
   * @param region the Fauna region group to connect to.
   * @param scope the scope (subdatabase and role) to use for this connection.
   * @param config allows for fine tuning of the underlying `faunadb` client.
   */
  constructor(
    region: Region,
    scope?: Scope,
    config?: Partial<ClientConfiguration>
  ) {
    this.region = region;

    this.endpoint = new URL(this.region.url);

    this.scope = scope;

    this.maxConns = 10;

    const secret =
      config?.secret ??
      (this.scope
        ? getScopedSecret(this.region.secret, this.scope)
        : this.region.secret);

    this.clientConfig = {
      // FIXME(neil): Add this back once `Query.isEnvTypecheck` is added.
      // typecheck: true,
      ...config,
      secret,
      endpoint: this.endpoint,
      // We want to enable `linearized` to work around https://faunadb.atlassian.net/browse/ENG-5364
      linearized: true,

      // THE BIG BOOK OF TIMEOUT:

      // When we make a request to fauna, we wait query_timeout_ms +
      // client_timeout_buffer_ms. If we haven't received a response from
      // core-router by then, we close the connection. core-router will see
      // this in their logs as a 499, but it isn't _really_ a response since
      // the connection is closed. This shows up in our logs as a resource
      // request with a response code of 0 and a duration >= 15 seconds.

      // Our metrics for client-closed connections:
      // https://fauna.datadoghq.com/rum/sessions?query=%40type%3Aresource%20%40application.id%3Af10b6a21-9af0-4350-8006-32570cbaa10b%20%40resource.status_code%3A0%20%40resource.url_host%3Adb.%2Afauna.com%20%40resource.duration%3A%3E%3D1.5e%2B10&agg_m=count&agg_m_source=base&agg_q=%40resource.url&agg_q_source=base&agg_t=count&cols=&fromUser=false&top_n=1000&top_o=top&viz=timeseries&x_missing=true&from_ts=1716928762322&to_ts=1717015162322&live=true

      // However, if the query runs for query_timeout_ms without resolving,
      // core-router will see this in their logs as a 440 and we'll see it in
      // _our_ logs as a resource request with a response code of 0 and a short
      // duration.

      // Our metrics for query timeouts:
      // https://fauna.datadoghq.com/rum/sessions?query=%40type%3Aresource%20%40application.id%3Af10b6a21-9af0-4350-8006-32570cbaa10b%20%40resource.status_code%3A0%20%40resource.url_host%3Adb.%2Afauna.com%20%40resource.duration%3A%3C1.5e%2B10&agg_m=count&agg_m_source=base&agg_q=%40resource.url&agg_q_source=base&agg_t=count&cols=&fromUser=false&top_n=1000&top_o=top&viz=timeseries&x_missing=true&from_ts=1716928942293&to_ts=1717015342293&live=true

      // 10 second timeout (the driver default is 5 seconds)
      query_timeout_ms: 15000,
      // 5 second "buffer" (this is the driver default, but including here to make it explicit)
      client_timeout_buffer_ms: 5000,
    };
    this.client = FaunaAPI.getClient(
      { secret, endpoint: this.endpoint },
      this.clientConfig
    );
  }

  /** A map to re-use existing clients if they have the same configuration. */
  static #clients: ClientMap = new Map();

  /**
   * Gets a client to query the using the secret
   * and endpoint provided, or the secret and endpoint
   * inferred from a {@link DatabasePath}
   * @param clientSpec a {@link ClientSpec}
   * @return a {@link Client} based on the clientSpec
   */
  static getClient(
    clientSpec: ClientSpec,
    clientConfig: Partial<ClientConfiguration>
  ): Client {
    return FaunaAPI.#getClient(clientSpec, FaunaAPI.#clients, clientConfig);
  }

  static #getClient(
    clientSpec: ClientSpec,
    clientMap: Map<string, Client>,
    clientConfig: Partial<ClientConfiguration> = {}
  ) {
    let secret;
    let endpoint;
    if (isDatabasePath(clientSpec)) {
      secret = getScopedSecret(clientSpec.regionGroup.secret, {
        dbPath: clientSpec.namePathStr,
      });
      endpoint = new URL(clientSpec.regionGroup.url);
    } else {
      ({ secret, endpoint } = clientSpec);
    }
    let clientKey = `${JSON.stringify(clientConfig)}`;
    if (!clientMap.has(clientKey)) {
      clientMap.set(
        clientKey,
        new Client({
          ...clientConfig,
          endpoint,
          secret,
          // We want to enable `linearized` to work around https://faunadb.atlassian.net/browse/ENG-5364
          linearized: true,
        })
      );
    }
    return clientMap.get(clientKey) as Client;
  }

  // Execute an arbitrary FQL query expression.
  async query<T extends QueryValue>(expr: Query) {
    return this.client?.query<T>(expr);
  }

  /** Throws an error unless this client is valid and authorized. */
  check(cacheTag?: string) {
    return useQuery(this, fql`1 + 1`, {}, cacheTag);
  }

  /**
   * Connects to a child database.
   * @param dbName the name of the child DB to connect to.
   * @param authScope the AuthScope (role or authDoc) to use for the connection.
   */
  connectTo(
    dbName: string,
    authScope?: AuthScope,
    config?: Partial<ClientConfiguration>
  ) {
    const dbPath =
      this.dbPath === "" || dbName === "" ? dbName : `${this.dbPath}/${dbName}`;
    const scope = { ...this.scope, ...authScope, dbPath };
    return FaunaAPI.connect(this.region, scope, config);
  }

  /**
   * Connects to Fauna.
   * @param region which region group to connect to.
   * @param scope the scope (dbPath & role) to use for the connection.
   * @param config allows for fine tuning of the underlying `faunadb` client.
   */
  static connect(
    region: Region,
    scope?: Scope,
    config?: Partial<ClientConfiguration>
  ) {
    return new FaunaAPI(region, scope, config);
  }
}
