import dedent from "dedent";

const english = {
  common_messages: {
    back_to_page: "Back to {0}",
    delete_confirm:
      "Are you sure you want to delete {0} {1}? This action cannot be undone.",
    update_confirm: "{0} {1} has been updated.",
  },
  actions: {
    add: "Add",
    run: "Run",
    run_as: "Run As",
    run_query_as: "Run Query As",
    edit: "Edit",
    export: "Export",
    save: "Save",
    open: "Open",
    okay: "Okay",
    authorize: "Authorize",
    deny: "Deny",
    cancel: "Cancel",
    create: "Create",
    delete: "Delete",
    remove: "Remove",
    settings: "Settings",
    personalSettings: "Personal Settings",
    go_back: "Go Back",
    go_home: "Go Home",
    go_login: "Go Login",
    back_to_login: "Back to login",
    back_to_verify: "Back to verify",
    back_to_dashboard: "Back to dashboard",
    close: "Close",
    copy: "Copy",
    copied: "Copied",
    copy_to_clipboard: "Copy to clipboard",
    copied_to_clipboard: "Copied to clipboard",
    revoke: "Revoke",
    change: "Change",
    open_in_v4: "Open in v4 editor",
    install: "Install",
  },
  errors: {
    try_again: "Please try again.",
    error: "Error",
    generic: "Something went wrong.",
    invalid_session: "Invalid session.",
    invalid_session_message: "Your session has expired, please log in again.",
    not_found: "The page you are looking for does not exist.",
    form_validation: {
      field_required: "This is a required field.",
      name_required: "Name is a required field.",
      name_contains_invalid_characters:
        "Name must begin with letters and include only letters, numbers, and underscores.",
      name_contains_reserved_word: "Name cannot be a reserved keyword.",
      end_time_lte_start_time:
        "The end time must be greater than the start time.",
      less_than_90_days: "The maximum supported date range is 90 days.",
      required: {
        name: "Name is a required field.",
        issuer: "Issuer is a required field.",
        jwks_uri: "JWKS Endpoint is a required field.",
        role: "Role is a required field.",
      },
      jwks_uri_invalid: "JWKS Endpoint must be a valid https endpoint.",
    },
  },
  attributes: {
    actions: "Actions",
    add: "Add",
    application_name: "App Name",
    audience: "Audience",
    body: "Body",
    built_in: "Built-In",
    community: "Community",
    created_at: "Created (UTC)",
    custom: "Custom",
    database: "Database",
    date: "Date",
    documentation: "Documentation",
    drivers: "Drivers",
    email: "Email",
    expires: "Expires",
    history_days: "History (days)",
    id: "ID",
    issuer: "Issuer",
    jwks_uri: "JWKS Endpoint",
    meta: "Metadata",
    name: "Name",
    permissions: "Permissions",
    query: "Query",
    role: "Role",
    roles: "Roles",
    size: "Size",
    status: "Status",
    success: "Success!",
    support: "Support",
    tools: "Tools",
    ttl: "TTL (days)",
    version: "Version",
    v10: "v10",
    v4: "v4",
    warning: "warning",
    data: "Data",
    predicate: "Predicate",
  },
  keyboard_shortcuts: {
    name: "Keyboard shortcuts",
    shortcuts: {
      execute: {
        name: "Execute operation",
        shortcut: "⌘/Ctrl + Enter",
      },
      navigate: {
        name: "Navigate query history",
        shortcut: "⌘/Ctrl + Up/Down",
      },
      clear: {
        name: "Clear editor",
        shortcut: "⌘/Ctrl + l/k",
      },
    },
  },
  usage: {
    name: "Usage",
    spending: "Cost Breakdown",
    metrics: {
      read: "Read",
      write: "Write",
      compute: "Compute",
      operations: "Operations",
      storage: "Database Storage",
      database_storage: "Database Storage",
      backup_storage: "Backup Storage",
      backups: "Backups",
      backup_snapshots: "Backup Snapshots",
      backup_restores: "Backup Restores",
      logs: "Logs",
      log_export: "Log Exports",
      realtime: "Realtime (Datadog)",
      egress: "Egress",
    },
    descriptions: {
      operations:
        "Combination of costs incurred from read, write and compute operations",
      storage: "Costs incurred from storage of data",
      backups: "Combination of costs incurred from backup storage and restores",
      logs: "Costs incurred from exported log data",
      egress: "Costs incurred from data egress",
    },
    no_access: "You do not have access to this feature.",
    tool_tip: dedent`
    View operational metrics for a selected Region Group, Database or child Database.
    Charts may take up to 1 hour to update.`,
    no_data: dedent`
      No usage data for the selected databases, metric and time frame.
      Please allow up to 1 hour for usage data from new queries to be
      populated.
    `,
  },
  pricing: {
    see_below_or_on_our: "See the pricing below or on our",
    pricing_page: "pricing page",
  },
  region: {
    name: "Region Group",
    plural: "Region Groups",
    attributes: {
      rate: "Rate",
      other: "Other",
      available: "Available",
      upon_request: "Upon request",
    },
    tooltip: "Region Group determines where your data resides.",
  },
  dashboard: {
    name: "Dashboard",
    explorer: "Explorer",
    account_settings: "Account Settings",
    personal_settings: "Personal Settings",
  },
  webshell: {
    name: "Shell",
    tooltips: {
      clear: "Clear shell",
      hide_output: "Hide output",
      expand_output: "Expand output",
      shortcuts: "Keyboard shortcuts",
      run_as:
        "Verify your ABAC configuration by running your query with an Admin or Server key, a specific Role, or a specific identity Document.",
    },
  },
  billing: {
    actions: {
      upgrade: "Upgrade",
      upgrade_with_free_trial: "Upgrade w/ Free Trial",
      choose: "Choose Plan",
    },
    attributes: {
      plan: "Plan",
      company_name: "Company name",
      billing_email: "Billing email",
      phone_number: "Phone number",
    },
    errors: {
      first_party_billing_permission:
        "You are subscribed to Fauna with a third party, such as AWS Marketplace. Please manage your plan and billing information in that location.",
    },
    upgrade: {
      messages: {
        upgrade_success:
          "Successful Upgrade - Your upgrade to the {0} Plan is complete!",
        upgrade_in_progress:
          "Pending Upgrade - Your upgrade to the {0} plan is in progress. Your access remains unchanged until it is complete.",
        contact_sales_success:
          "A member of our team will reach out shortly to follow up on your request.",
      },
      errors: {
        upgrade_fail: "Unable to upgrade. Please try again.",
        three_d_secure_fail:
          "Payment Failed - There was an unexpected issue confirming your payment. Please try again.",
        payment_required:
          "Payment Failed - We were unable to process your selected form of payment. Make sure you have added a valid payment method.",
      },
    },
    stripe_customer_info: {
      actions: {
        update: "Update billing info",
      },
      errors: {
        update_error: "Unable to update billing information. Please try again.",
      },
      messages: {
        update:
          "Billing updates will be conveyed via the provided contact information.",
        update_success: "Successfully updated billing information.",
      },
    },
    invoices: {
      actions: {
        resolve: "Resolve Now",
      },
      requires_action:
        "Invoice Payment Needs Attention - We were unable to process your selected form of payment. Please resolve.",
      payment_success:
        "Invoice Payment Complete! - Thank you for completing your invoice payment.",
      errors: {
        load_fail: "Unable to load invoices.",
      },
      messages: {
        empty: "You have not received any invoices yet.",
      },
    },
    payment_methods: {
      actions: {
        add: "Add payment method",
        remove: "Remove payment method",
        set_default: "Set default payment method",
        set_default_min: "Set as default",
      },
      messages: {
        remove_success: "Successfully removed payment method.",
        remove_confirm: "Are you sure you want to remove this payment method?",
        set_default_confirm:
          "Are you sure you want to make this your default payment method? It will be charged during the next billing cycle.",
      },
      errors: {
        confirmation_error_title: "Payment method authentication failed",
        confirmation_error:
          "Payment Failed - We are unable to authenticate your payment method. Please choose a different payment method and try again.",
        declined_error_title: "Payment method declined",
        declined_error:
          "Payment Failed - Your payment method was declined. Please choose a different payment method and try again.",
        setup_intent_error:
          "We are unable to accept new forms of payment right now. Please try again.",
        confirm_setup_intent_error:
          "We were unable to complete your payment method setup. Please try again.",
        remove_error: "Unable to remove payment method.",
        remove_default_method_error:
          "You may not remove your default payment method.",
        set_default_method_error:
          "Unable to set default payment method. Please try again.",
      },
    },
    trial: {
      badge: "30 Day Free Trial",
    },
  },
  auth: {
    actions: {
      sign_in: "Sign in",
      sign_up: "Sign Up",
      sign_out: "Sign out",
      verify_email: "Verify email",
      send_verification_email: "Send verification email",
      request_new_verification_email: "Request a new verification link",
      will_receive_email:
        "You will receive an email with a link to activate your account.",
      will_receive_change_email:
        "You will receive an email with a link to update your account email.",
      reset_password: "Reset password",
      forgot_password: "Forgot password",
      request_password_reset: "Request password reset",
    },
    attributes: {
      email: "Email",
      company_email: "Company Email",
      first_name: "First Name",
      last_name: "Last Name",
      company_name: "Company Name",
      name: "Name",
      password: "Password",
      new_password: "New Password",
      confirm_password: "Confirm Password",
    },
    messages: {
      welcome: "Welcome to Fauna",
      reset_password: "Reset Password",
      forgot_password: "Forgot Password",
      password_reset_success: "Your password has been reset.",
      password_reset_email_sent:
        "If there is an account associated with this email, a password reset link will be sent.",
      new_user: "Not a Fauna customer yet?",
      existing_user: "Already have a Fauna account?",
      oauth2_request: "Authorize API Access for {0}",
    },
    errors: {
      temporarily_locked: "Account temporarily locked",
      account_cooldown:
        "We've detected an unusual number of attempts to login to your account. Your account has " +
        "been locked for one hour. If you believe you've tripped this by mistake or have concerns" +
        " please contact our",
      account_locked_until_support:
        "We've detected an unusual number of attempts to authorize your account. " +
        "Your account has been locked. To resolve the situation please contact our",
      sign_in_fail: "Unable to sign in.",
      sign_up_fail: "Unable to sign up.",
      verification_required: "Almost there",
      verification_fail: "Unable to verify email.",
      invalid_new_email:
        "The email you have submitted does not match the one you requested. Please issue a new email change request to use '{0}'.",
      missing_verification_token:
        "We sent you an email with a link to activate your Fauna account",
      expired_verification_link: "Oops! That link has expired",
      invalid_verification_link:
        "Invalid verification token. Check your email for the latest account verification link.",
      oauth_fail: "Unable to sign in.",
      oauth_session_fail:
        "Error while creating session. Please try again or contact support.",
      password_reset_fail: "Unable to reset password.",
      request_password_reset_fail: "Unable to initiate password reset.",
      account_exists: "This account may already exist.",
      invalid_domain: "Email contains invalid domain.",
      invalid_format: "Email contains invalid format.",
      invalid_credentials: "Invalid credentials provided.",
      email_already_in_use:
        "This email is already associated with a Fauna account.",
      passwords_must_match: "Passwords must match.",
      invalid_password_reset_token:
        "Invalid password reset token provided, please initiate a new reset request.",
      already_verified: "Your account is already verified please sign in.",
      already_verified_pending_email:
        "This email has already been verified by another user. Please return to profile settings to request a new email.",
      no_pending_email:
        "There is no active change email request for this account. Please return to profile settings to request a new email",
      invalid_pending_email:
        "The provided email is not pending verification. Please check your URL or return to profile settings to request a new email token.",
      token_reset_limit:
        "You have exceeded the number of allowed email verification token resets. To complete activiation, please contact support.",
      unexpected_error: "An unexpected error occurred please try again.",
      new_activation_link_failed:
        "Whoops! Failed to send activation link, please try again.",
    },
    terms: {
      first: "By signing up for Fauna, you are accepting the",
      second: "and the",
    },
  },
  collection: {
    name: "Collection",
    plural: "Collections",
    actions: {
      create: "Create Collection",
      edit: "Edit Collection",
      delete: "Delete Collection",
    },
    empty: {
      title: "Create your first Collection",
    },
    messages: {
      about:
        "Collections are containers for holding Documents and used to define database schema.",
      empty:
        "Collections are like tables in other databases. They contain documents that are like rows.",
      create_confirm: "Successfully created Collection.",
    },
    errors: {
      history_invalid: "History must be a positive integer or null.",
      ttl_invalid: "TTL must be a positive integer or null.",
    },
  },
  function: {
    name: "Function",
    plural: "Functions",
    actions: {
      edit: "Edit Function",
      create: "Create Function",
      delete: "Delete Function",
    },
    empty: {
      title: "Create your first Function",
    },
    messages: {
      about:
        "Functions are similar to stored procedures. They are queries that can be run repeatedly.",
      empty:
        "Functions are similar to stored procedures. They are queries that can be run repeatedly.",
      create_confirm: "Successfully created function.",
      copied_to_clipboard: "Function ID copied to clipboard.",
    },
    errors: {
      invalid_function_body: "Invalid function body.",
      check_syntax: "Check your syntax and try again.",
    },
  },
  role: {
    name: "Role",
    plural: "Roles",
    attributes: {
      privilege: {
        name: "Privilege",
        plural: "Privileges",
      },
      membership: {
        name: "Membership",
        plural: "Memberships",
      },
    },
    actions: {
      edit: "Edit Role",
      create: "Create Role",
      delete: "Delete Role",
      grant_access: "Grant Access",
      revoke_access: "Revoke Access",
      custom_privileges: "Custom Privileges",
      privilege: {
        add: "Add Privileges",
        remove: "Remove Privileges",
      },
      membership: {
        add: "Add Membership",
        remove: "Remove Membership",
      },
    },
    privilege_actions: {
      read: "Read",
      write: "Write",
      create: "Create",
      delete: "Delete",
      create_with_id: "Create with ID",
      history_read: "History Read",
      history_write: "History Write",
      call: "Call",
    },
    empty: {
      title: "Create your first Role",
    },
    messages: {
      about:
        "Roles provide configurable security rules. They are the core schema for ABAC.",
      empty:
        "Roles provide configurable security rules. They are the core schema for ABAC.",
    },
    tooltips: {
      name: "Role name. It can be any string except for events, sets, or self and any string that includes the % percent character.",
    },
  },
  accessprovider: {
    name: "Access Provider",
    plural: "Access Providers",
    actions: {
      edit: "Edit Access Provider",
      create: "Create Access Provider",
      delete: "Delete Access Provider",
    },
    empty: {
      title: "Create your first Access Provider",
    },
    messages: {
      about:
        "Access Providers are used to integrate a third party identity provider with Fauna databases.",
      empty:
        "Access Providers are used to integrate a third party identity provider with Fauna databases.",
    },
    tooltips: {
      name:
        "Unique name for the external identity provider. \n" +
        "Can’t be events, sets, self, documents, or underscore (_) character and can’t include the percent (%) character.",
      issuer:
        "Unique HTTPS URL for the identity provider that is used to grant access to Fauna. The issuer is typically an account or application URL provided by the identity provider.",
      jwks_uri:
        "Valid HTTPS URI. This should be the JSON Web Key (JWK) that signs the JSON Web Token (JWT) from the identity provider.",
      audience:
        "Unique URL for your database that should be used in the audience configuration for an identity provider. Fauna creates this field when you create a database.",
    },
  },
  document: {
    name: "Document",
    plural: "Documents",
    data: "Document Data",
    field: "Field",
    value: "Value",
    fields: "Fields",
    values: "Values",
    actions: {
      create: "Create Document",
      delete: "Delete Document",
      edit: "Edit Document",
      search: "Search Documents",
      copy_to_clipboard: "Copy Document ID",
    },
    empty: {
      title: "Create your first Document",
    },
    tooltip: {
      example_body_description: "You can use JavaScript syntax. For example:",
      example_body_code:
        '{\n  itemName: "apple",\n  price: 0.42 + 1.50,\n  perishable: true\n}',
    },
    messages: {
      about:
        "Documents are the basic unit of information in Fauna. All data is stored in Documents.",
      save_return_to_collections: "Save and go back",
      copied_to_clipboard: "Document ID copied to clipboard.",
    },
    errors: {
      duplicate_field: "Cannot contain duplicate fields.",
      empty_value: "Values cannot be empty.",
    },
  },
  index: {
    name: "Index",
    plural: "Indexes",
    attributes: {
      term: {
        name: "Term",
        plural: "Terms",
      },
      value: {
        name: "Value",
        plural: "Values",
      },
    },
    actions: {
      create: "Create Index",
      delete: "Delete Index",
      edit: "Edit Index",
    },
    messages: {
      about:
        "Indexes allow you to retrieve documents. They act as a lookup table that improves query performance.",
      create_confirm: "Successfully created Index.",
      empty:
        "Indexes allow you to retrieve documents. They act as a lookup table that improves query performance.",
    },
    empty: {
      title: "Create your first Index",
    },
    errors: {
      exists: "An index with this name already exists.",
      empty_term: "Terms cannot be empty.",
      empty_value: "Values cannot be empty.",
    },
  },
  database: {
    name: "Database",
    plural: "Databases",
    actions: {
      create: "Create Database",
      select: "Select a Database",
      deleteValidationError: "Enter the confirmation text to proceed.",
    },
    empty: {
      title: "Create your first Database",
      title_child: "Create your first Child Database",
    },
    messages: {
      no_spaces: "Cannot contain spaces",
      settings_confirm: "Database settings updated.",
      begin: "Begin your Fauna journey by creating your first database",
      choose: "Select a database from the left panel to get started",
    },
    errors: {
      create_fail: "Unable to create database.",
    },
  },
  key: {
    name: "Key",
    plural: "Keys",
    actions: {
      create: "Create key",
      revoke: "Revoke Key",
    },
  },
  backup: {
    name: "Backup",
    plural: "Backups",
    states: {
      enabled: "Enabled",
      disabled: "Disabled",
    },
    schedule: {
      message: {
        prefix: "At 12:00 AM",
        new_prefix: "daily",
      },
      daily: {
        default_cron_expression: "0 0 * * *",
        label: "Daily",
      },
      weekly: {
        default_cron_expression: "0 0 * * 1",
        label: "Weekly",
        invalid_cron_expression: "Day (week) should be within [1-7]",
      },
      monthly: {
        default_cron_expression: "0 0 1 * *",
        label: "Monthly",
        invalid_cron_expression: "Day (month) should be within [1-28]",
      },
      custom: {
        default_cron_expression: "0 0 L * *",
        label: "Last day of month",
        invalid_cron_expression: "Entered cron expression is invalid",
      },
    },
    cron_expression: {
      hour: "hour",
      minute: "minute",
      day_of_month: "day (month)",
      month: "month",
      day_of_week: "day (week)",
    },
    attributes: {
      emabled: "Enabled",
      frequency: "Frequency",
      retention: "Retention",
      next_snapshot: "Next Snapshot",
      latest_snapshot: "Latest Snapshot",
    },
    errors: {
      create_fail: "Unable to configure backups.",
      create_fail_new_database:
        "Your database was created, but we were unable to configure backups.",
      max_depth_exceeded:
        "Backups cannot be enabled for databases with >= 10 parents.",
    },
    actions: {
      table_action: "Configure backups for this database.",
    },
  },
  snapshot: {
    name: "Snapshot",
    plural: "Snapshots",
    attributes: {
      size: "Size",
      date: "Date",
      status: "Status",
      expires: "Expires",
    },
    actions: {
      select: "Select a snapshot",
    },
    messages: {
      source: "Source snapshot",
    },
  },
  copy: {
    name: "Copy",
    actions: {
      create: "Copy Data",
      submit: "Start Copy",
      table_action: "Create a new database from a snapshot.",
    },
    messages: {
      about: "This will copy data from an existing snapshot.",
      failure: "Failed to initiate database copy",
      success: "Database copy started successfully.",
      destination: "Destination",
      destination_name: "Database Name",
      dest_db_placeholder: "ex: my_database_copy",
      confirm:
        "I understand the destination database will be unavailable until the data copy is complete.",
      snapshot_error: "You must choose a snapshot.",
      destination_error: "You must select a destination database or region",
      copy_name_error: "You must enter a name for the data copy",
      confirm_error: "You must acknowledge the warning above.",
      in_progress: "Copy in progress",
      no_submit:
        "Please fill out the form and click the checkbox to start a copy.",
    },
  },
  restore: {
    name: "Restore",
    actions: {
      create: "Restore Data",
      submit: "Start Restore",
      table_action: "Restore this database from a previous snapshot.",
    },
    messages: {
      destination: "Destination Database",
      previous: "Previous snapshots",
      warning:
        "This database will be unavailable until the restore operation has completed (active shell tabs will also be closed).",
      warning_link0: "Also see our ",
      warning_link1: "Backups documentation",
      warning_link2: " for additional details.",
      confirm:
        "I understand the database will be unavailable until the restore is complete.",
      success: "Restore started successfully.",
      failure: "Failed to start database restore.",
      in_progress: "Restore in progress",
      no_submit:
        "Please select a snapshot and click the checkbox to start a restore.",
      limit:
        "You have five active restores or copies currently in progress (Fauna supports up to five concurrent restore operations). Please wait for one or more of these restores to finish before submitting a new request.",
    },
  },
  tooltips: {
    track_days:
      "Document history is retained for at least this many days. Clearing the field will retain history forever, but please note that this will increase storage utilization. This field defaults to 0 days and can be changed later.",
    ttl: "Documents are deleted this many days after their last write. Documents of the collection will be removed if they have not been updated within the configured TTL. This optional field can be changed later.",
    class_name:
      "Cannot be events, sets, self, documents, or _. Please wait a few moments before using a recently deleted collection name. This required field can be changed later.",
    document_collection:
      "Select the collection this document should belong to.",
  },
  account_settings: {
    name: "Account Settings",
    contact_name: "Contact Name",
    first_name: "First Name",
    last_name: "Last Name",
    contact_email: "Contact Email",
    company_name: "Company Name",
    website: "Website",
    errors: {
      update_contact_fail: "Unable to update contact information.",
    },
  },
  team_management: {
    name: "Team Management",
    actions: {
      invite: "Send Invite",
      resend_invite: "Resend Invite",
      cancel_invite: "Cancel Invite",
      remove_member: "Remove Member",
      accept_invite: "Join the Team",
    },
    errors: {
      cancel_invite_fail: "Unable cancel invite.",
      remove_member_fail: "Unable remove member.",
      resend_invite_fail: "Unable to resend invite.",
      send_invite_fail: "Unable to send invite.",
    },
  },
  account_keys: {
    name: "Account Keys",
    key_name: "Name",
    ttl: "TTL",
    ttl_label: "TTL (days)",
    actions: {
      create: "Create Account Key",
      delete: "Delete Account Key",
    },
    errors: {
      create_key_fail: "Unable to create new account key.",
      delete_key_fail: "Unable to delete account key.",
    },
  },
  oauth_apps: {
    errors: {
      revoke_fail: "Unable to revoke OAuth Application",
    },
  },
  integrations: {
    errors: {
      invalid_api_key:
        "The API key is invalid. Please delete and re-add the integration to get a new API key.",
    },
  },
  logs: {
    name: "Logs",
    actions: {
      export: {
        header: "Export logs",
        sub_header: "Select a date range",
      },
    },
  },
  personal_settings: {
    personal_info: {
      details_header: "Personal Details",
      email_header: "Login Email",
      name: "Name",
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      update_success: "Your personal information has been updated.",
      update_failure: "Unable to update personal information.",
    },
    change_email: {
      current: "Current Email Address",
      new: "New Email Address",
    },
    change_password: {
      header: "Change Password",
      tooltip: "You will be logged out after change password.",
      current_password: "Current Password",
      new_password: "New Password",
      confirm_new_password: "Confirm New Password",
      update_failure: "Unable to update password.",
    },
    mfa_guide:
      "Multi-factor authentication is an enhanced security measure. Once enabled, you'll be required to give two types of identification when you log into Fauna. Only OTP apps (like Google Authenticator and similar) are supported.",
  },
  footer: {
    copyright: "Copyright {0} Fauna Inc.",
    terms: "Terms of Use",
    privacy: "Privacy Policy",
  },
  demo: {
    errors: {
      create_fail: "Unable to create demo data.",
      create_fail_new_database:
        "Your database was created, but we were unable to create demo data.",
    },
  },
};

export default english;
