import { FC } from "react";

import {
  Card,
  Layout,
  Text,
  Link,
  Grid,
  BirdLogo,
  TextLogo,
} from "frontend-components";
import { tx } from "modules/language/translate";
import { Logos } from "components/forms/auth/Logos";
import { BrowserSupport } from "pages/Login/BrowserSupport";
import { useBrowserSupport } from "modules/utils/browser";
import { FaunaUnavailableBanner } from "modules/context/StatusBannerContext";

import "./AuthLayout.scss";

type AuthLayoutProps = {
  form: JSX.Element;
  showMessaging?: boolean;
};

const WEBSITE_TERMS_OF_USE_URL = "https://fauna.com/terms";
const WEBSITE_PRIVACY_POLICY = "https://fauna.com/privacy";

const AuthLayoutFooter = () => {
  return (
    <Layout>
      <Text size="md" theme="secondary" textAlign="center">
        {tx("footer.copyright", [new Date().getFullYear().toString()])}
        &nbsp;
        {tx("auth.terms.first")}{" "}
        <Link href={WEBSITE_TERMS_OF_USE_URL} theme="primary">
          {tx("footer.terms")}
        </Link>{" "}
        {tx("auth.terms.second")}{" "}
        <Link href={WEBSITE_PRIVACY_POLICY} theme="primary">
          {tx("footer.privacy")}
        </Link>
      </Text>
    </Layout>
  );
};

const AuthLayout: FC<AuthLayoutProps> = ({ form, showMessaging }) => {
  const browserInfo = useBrowserSupport();
  return browserInfo && !browserInfo?.isSupported ? (
    <BrowserSupport browserInfo={browserInfo} />
  ) : (
    <Layout
      width="screen"
      height="screen"
      background="secondary"
      overflow="auto"
    >
      <FaunaUnavailableBanner width="full" />
      <Grid
        container
        columns={2}
        height="100%"
        width="100%"
        direction="row-reverse"
        columnSpacing={0}
        rowSpacing={0}
      >
        <Grid
          item
          size={true}
          display="flex"
          justifyContent="center"
          height="100%"
        >
          <Layout padding="sm" className="auth-layout-form-container">
            <Layout className="auth-layout-form-content" height="min" gap="sm">
              <Logos alwaysDisplay={!showMessaging} />
              <Card padding="sm" theme="transparent" shadow="none" width="full">
                {form}
              </Card>
            </Layout>
            <Layout flow="top-bottom" height="min">
              <AuthLayoutFooter />
            </Layout>
          </Layout>
        </Grid>
        {showMessaging && (
          <Grid item xs={2} md={1} height="100%" className="auth-layout-logos">
            <Layout background="pattern">
              <Layout
                justifyContent="center"
                alignItems="center"
                flow="left-right"
                className="auth-layout-logos-gap"
              >
                <div className="bird-logo-wrapper-auth">
                  <BirdLogo />
                </div>
                <div className="text-logo-wrapper-auth">
                  <TextLogo />
                </div>
              </Layout>
            </Layout>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

export default AuthLayout;
