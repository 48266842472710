import { Query, QueryValue, fql } from "fauna";
import { FaunaAPI } from ".";
import {
  ResourceAccessor,
  ResourceAccessors,
  ShellSaveParams,
  buildAccessor,
} from "./resource";
import { QueryForDisplay } from "./resources";
import { display } from "./display";

const get = (mod: string) => (name: string) => {
  return fql`${fql([mod])}.byName(${name})!`;
};

// Each of these *Query functions take two argument lists, so they should be
// called like `getQuery("Function")("myFunction")`.

export const getQuery =
  (mod: string) =>
  (name: string): QueryForDisplay => {
    return {
      query: get(mod)(name),
      display: `${mod}.byName("${name}")`,
    };
  };

export const createQuery =
  (mod: string) =>
  <T extends QueryValue>(args: T): QueryForDisplay => {
    return {
      query: fql`${fql([mod])}.create(${args})`,
      display: `${mod}.create(${display(args)})`,
    };
  };

export const updateQuery =
  (mod: string) =>
  <T extends QueryValue>({
    name,
    args,
  }: {
    name: string;
    args: T;
  }): QueryForDisplay => {
    return {
      query: fql`${get(mod)(name)}.update(${args})`,
      display: `${mod}.byName("${name}")!.update(${display(args)})`,
    };
  };

export const replaceQuery =
  (mod: string) =>
  <T extends QueryValue>({
    name,
    args,
  }: {
    name: string;
    args: T;
  }): QueryForDisplay => {
    return {
      query: fql`${get(mod)(name)}.replace(${args})`,
      display: `${mod}.byName("${name}")!.replace(${display(args)})`,
    };
  };

export const shellSaveQuery =
  (mod: string) =>
  ({ name, query }: { name: string; query: string }): QueryForDisplay => {
    return {
      query: fql`${get(mod)(name)}.replace(${fql([query])})`,
      display: `${mod}.byName("${name}")!.replace(${query})`,
    };
  };

export const deleteQuery =
  (mod: string) =>
  (name: string): QueryForDisplay => {
    return {
      query: fql`${get(mod)(name)}.delete()`,
      display: `${mod}.byName("${name}")!.delete()`,
    };
  };

export class QueryAccessors<
  CreateArgs extends QueryValue,
  UpdateArgs extends QueryValue,
  Result extends QueryValue
> implements ResourceAccessors
{
  api: FaunaAPI;

  // Accessors
  create: ResourceAccessor<CreateArgs, Result>;
  get: ResourceAccessor<string, Result>;
  update: ResourceAccessor<{ name: string; args: UpdateArgs }, Result>;
  replace: ResourceAccessor<{ name: string; args: CreateArgs }, Result>;
  delete: ResourceAccessor<string, Result>;

  shellSave: ResourceAccessor<ShellSaveParams, string>;

  /**
   * Constructs a new set of accessors for the given resource. The name should
   * be a module like `Function` or `Collection`.
   */
  constructor(api: FaunaAPI, moduleName: string) {
    this.api = api;

    const retrieveQ = (resourceId: string) =>
      getQuery(moduleName)(resourceId).query;

    this.create = this.buildAccessor<CreateArgs, Result>(
      createQuery(moduleName)
    );
    this.get = this.buildAccessor<string, Result>(getQuery(moduleName));
    this.update = this.buildAccessor<
      { name: string; args: UpdateArgs },
      Result
    >(updateQuery(moduleName), retrieveQ);
    this.replace = this.buildAccessor<
      {
        name: string;
        args: CreateArgs;
      },
      Result
    >(replaceQuery(moduleName), retrieveQ);
    this.shellSave = this.buildAccessor<
      {
        name: string;
        query: string;
      },
      string
    >(shellSaveQuery(moduleName), retrieveQ);
    this.delete = this.buildAccessor<string, Result>(
      deleteQuery(moduleName),
      retrieveQ
    );
  }

  buildAccessor<ParamT, DataT extends QueryValue>(
    queryBuilderFunction: (args: ParamT) => QueryForDisplay,
    retrieveQuery?: (resourceId: string) => Query
  ): ResourceAccessor<ParamT, DataT> {
    return buildAccessor(queryBuilderFunction, this.api, retrieveQuery);
  }

  [key: string]: any;
}
