import React from "react";
import { BrowserSupportType } from "modules/utils/browser";
import { Layout, Card, Text, Link } from "frontend-components";

export const BrowserSupport: React.FC<any> = ({
  browserInfo,
}: {
  browserInfo: BrowserSupportType;
}) => {
  const { upgradeLink } = browserInfo;
  const supportedBrowsersLink =
    "https://docs.fauna.com/fauna/current/requirements";
  return (
    <Layout
      width="screen"
      height="screen"
      justifyContent="center"
      alignItems="center"
    >
      <Card shadow="sm" padding="lg">
        <Text>
          You are using a browser version that is not supported. Please see our{" "}
          <Link href={supportedBrowsersLink}>list</Link> of supported versions
          and{" "}
          {upgradeLink ? <Link href={upgradeLink}>upgrade</Link> : "upgrade"} to
          use the dashboard.
        </Text>
      </Card>
    </Layout>
  );
};
