import React from "react";
import { datadogRum } from "@datadog/browser-rum-slim";
import ReactDOM from "react-dom/client";
import App from "./App";
import env from "src/env";
import { initializeAnalytics } from "modules/analytics/analytics";
import * as translate from "modules/language/translate";
import english from "modules/language/en-us";
import { useAuthenticationTypeIntercept } from "./modules/utils/fetchIntercept";
import {
  FeatureFlag,
  getSessionFeatureFlag,
} from "./modules/utils/featureFlags";

translate.setDictionary(english);

const dataDogConfig =
  env.VITE_DD_APPLICATION_ID && env.VITE_DD_CLIENT_TOKEN
    ? {
        applicationId: env.VITE_DD_APPLICATION_ID,
        clientToken: env.VITE_DD_CLIENT_TOKEN,
        // don't call prod 'beta'
        env: env.VITE_ENVIRONMENT === "beta" ? "prod" : env.VITE_ENVIRONMENT,
      }
    : null;

if (dataDogConfig) {
  datadogRum.init({
    ...dataDogConfig,
    site: "datadoghq.com",
    service: "fql-x-dashboard",
    // Specify a version number to identify the deployed version of your application in Datadog
    version: env.VITE_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackViewsManually: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: (event) => {
      // Redact sensitive information from what we send to DD
      const redact = (s = "") => s.replace(/token=[^&]*/, "token=REDACTED");
      const { view, resource, error } = event;
      view.url = redact(view.url);
      if (view?.referrer) view.referrer = redact(view.referrer);
      // @ts-ignore
      if (resource?.url) resource.url = redact(resource?.url);
      // @ts-ignore
      if (error?.resource?.url) error.resource.url = redact(error.resource.url);
      return true;
    },
  });
}

// If we're monitoring session keys, wrap the fetch function on the window object
// so we can detect if keys from the session are used.
if (getSessionFeatureFlag(FeatureFlag.MONITOR_AUTHENTICATION_TYPES)) {
  useAuthenticationTypeIntercept({ target: window, rum: datadogRum });
}

initializeAnalytics({
  gtmId: env.VITE_GTM_ID,
  gaId: env.VITE_GA_ID,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
