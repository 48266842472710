import { SWRConfig } from "swr";
import Routes from "routes/Routes";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import DefaultErrorBoundary from "pages/Error/DefaultErrorBoundary";

import "./App.scss";
import "assets/fontawesome/css/light.min.css";
import "assets/fontawesome/css/brands.min.css";
import { ToastProvider } from "modules/context/ToastContext";
import { StatusBannerContextProvider } from "modules/context/StatusBannerContext";

const swrConfig = {
  errorRetryCount: 1,
  revalidateOnFocus: false,
};

const App = () => {
  return (
    <div className="App">
      <SWRConfig value={swrConfig}>
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={DefaultErrorBoundary}>
            <ToastProvider>
              <StatusBannerContextProvider>
                <Routes />
              </StatusBannerContextProvider>
            </ToastProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </SWRConfig>
    </div>
  );
};

export default App;
