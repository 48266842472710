import { FC } from "react";

import { PropTypes } from "./FullScreenOverlayLayout.types";

import "./FullScreenOverlayLayout.scss";

/**
 * A full page layout with absolute position that will sit on top of all other elements.
 *  This is meant to be used for things like error pages.
 * @param props.children - The contents of the page.
 * @returns FullScreenOverlayLayout
 */
const FullScreenOverlayLayout: FC<PropTypes> = ({ children }) => {
  return <div className="full-screen-overlay-layout">{children}</div>;
};

export default FullScreenOverlayLayout;
