import { create } from "zustand";

interface PollInvoices {
  pollInvoices: boolean;
}

interface PollInvoicesActions {
  setPollInvoices: (pollInvoices: boolean) => void;
}

export const usePollInvoicesStore = create<PollInvoices & PollInvoicesActions>(
  (set) => ({
    pollInvoices: false,
    setPollInvoices: (pollInvoices) => set({ pollInvoices }),
  })
);
