import useMeasure from "react-use-measure";
import Navbar from "components/navigation/Navbar";

import "./NavLayout.scss";

import { Layout } from "frontend-components";

const NavLayout = (props: any) => {
  const [pageRef, pageBounds] = useMeasure({ debounce: 0 });
  return (
    <Layout flow="top-bottom" height="screen">
      <Navbar />
      {/* This layout used to determine height of the rest of screen after static navbar is rendered */}
      <Layout flow="top-bottom" height="full" ref={pageRef}>
        {/*
        TODO: Mui-Grid uses the same mechanism as useMeasure to recompute bounds. I started trying
        to implement a Grid here but for the sake of time paused since there were some hiccups. Worth
        looking into again in the future so we can get away from managing static dimensions in code.

        This div is  used to set static height for children to reference for scrolling limits.
        Use absolute position because navbar is positioned absolute and absolute means it
        is positioned relative to the nearest positioned ancestor. accounts for navbar height
        when resizing page */}
        <div
          style={{
            height: pageBounds.height || "100%",
            width: pageBounds.width || "100%",
            position: "absolute",
          }}
        >
          {props.children}
        </div>
      </Layout>
    </Layout>
  );
};

export default NavLayout;
