import { useState } from "react";
import { Icon, Text, Grid, Tooltip } from "frontend-components";
import "./Notification.scss";

export const Notification = ({
  icon,
  message,
  theme,
  additionalAction,
  onClose = () => {},
}: {
  icon: string;
  message: string;
  theme: "info" | "error" | "success";
  additionalAction?: JSX.Element;
  onClose?: () => void;
}) => {
  const [visible, setVisible] = useState(true);

  return (
    <Grid
      container
      alignItems="center"
      height="100%"
      width="100%"
      rowSpacing={0}
      columnSpacing={0}
      padding={"0 25px 0 25px"}
      columnGap={2}
      className={`notification-${theme}`}
      display={visible ? undefined : "none"}
    >
      <Grid item size={false}>
        <Tooltip
          content={
            <Text size="lg" theme="inherit">
              {message}
            </Text>
          }
          placement="right"
        >
          <Icon
            iconClassName={icon}
            theme="dark"
            wrapper="span"
            hoverable={true}
          />
        </Tooltip>
      </Grid>
      <Grid item size={true} overflow="hidden">
        <Text
          textAlign="center"
          theme="dark"
          size="xl"
          showEllipsis={true}
          style={{ whiteSpace: "nowrap" }}
        >
          {message}
        </Text>
      </Grid>
      {additionalAction && (
        <Grid item size={false}>
          {additionalAction}
        </Grid>
      )}
      <Grid item size={false}>
        <Icon
          iconClassName="fa-xmark"
          theme="dark"
          wrapper="span"
          hoverable={true}
          onClick={() => {
            setVisible(false);
            onClose();
          }}
        />
      </Grid>
    </Grid>
  );
};
