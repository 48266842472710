import * as React from "react";
import { useLocation } from "react-router-dom";
import { tx } from "modules/language/translate";
import { hasLocalSession } from "modules/session";
import SupportTeamLink from "components/links/SupportTeamLink";
import ErrorPage from "./ErrorPage";
import { datadogRum } from "@datadog/browser-rum-slim";
import { FallbackProps } from "react-error-boundary";

import { Text, Link } from "frontend-components";

const DefaultErrorBoundary: React.FC<FallbackProps> = ({ error }) => {
  const location = useLocation();

  if (!hasLocalSession()) {
    datadogRum.addError(error, {
      error_type: "dead_session",
      location: location.pathname,
    });
    return (
      <ErrorPage
        title="Your session has expired."
        message="Your session has expired. Please login again."
        buttonText={tx("actions.back_to_login")}
        onButtonClick={() => {
          window.location.href = `${window.location.origin}/login`;
        }}
      />
    );
  }

  datadogRum.addError(error, {
    error_type: "whoops",
    location: location.pathname,
  });
  return (
    <ErrorPage
      title="Whoops!"
      message={
        <Text wrapper="div">
          An unknown error occurred, if the issue persists check out our{" "}
          <Link href="https://status.fauna.com/" external={true}>
            Status Page
          </Link>{" "}
          or contact our <SupportTeamLink />.
        </Text>
      }
      buttonText={tx("actions.back_to_login")}
      onButtonClick={() => {
        window.location.href = `${window.location.origin}/login`;
      }}
    />
  );
};

export default DefaultErrorBoundary;
