import { Query } from "fauna";
import {
  QueryFailure,
  QuerySuccess,
  QueryValue,
} from "fauna/dist/wire-protocol";
import useSWR, { SWRConfiguration } from "swr";
import { SWRCache, SWRCacheState, DEFAULT_SWR_OPTIONS } from ".";
import { FaunaAPI } from "..";

const { LOADING, VALIDATING, ERROR, READY } = SWRCacheState;

/**
 * Executes a fauna query and uses SWR to cache the result.
 *
 * **WARNING:** SWR may trigger revalidation for a variety of reasons; this will
 * re-execute the query. So *DO NOT use `useQuery` with any queries that WRITE
 * data.*
 *
 * @template T the type of the query result.
 * @param api the `FaunaAPI` to execute the query.
 * @param expr the query expression to execute.
 * @param swrOptions options for fine-tuning SWR.
 */
export function useQuery<T extends QueryValue>(
  api: FaunaAPI,
  expr: Query,
  swrOptions: SWRConfiguration<
    QuerySuccess<T>,
    QueryFailure["error"]
  > = DEFAULT_SWR_OPTIONS,
  cacheTag?: string
): SWRCache<QuerySuccess<T>, any, QueryFailure["error"]> {
  const { data, error, isValidating, mutate } = useSWR<
    QuerySuccess<T>,
    QueryFailure["error"]
  >(api.cacheKey(expr, cacheTag), () => api.query<T>(expr), swrOptions);

  const state =
    !error && !data
      ? LOADING
      : isValidating
      ? VALIDATING
      : error
      ? ERROR
      : READY;

  return { state, data, error, mutate };
}

export class SchemaItemNotFoundError extends Error {}

/**
 * Executes a GET request, and caches by path.
 *
 * @template T the type of the query result.
 * @param api the `FaunaAPI` to execute the query.
 * @param expr the query expression to execute.
 * @param swrOptions options for fine-tuning SWR.
 */
export function useGet<T>(
  api: FaunaAPI,
  path: string,
  swrOptions: SWRConfiguration<T> = DEFAULT_SWR_OPTIONS
): SWRCache<T> {
  const { data, error, isValidating, mutate } = useSWR(
    api.cachePath(path),
    async () => {
      const res = await fetch(api.endpoint + path, {
        headers: {
          Authorization: "Bearer " + api.secret,
        },
      });

      const response_body = await res.json();
      if (!res.ok) {
        if (res.status === 404) {
          throw new SchemaItemNotFoundError(res.statusText);
        } else {
          throw new Error(response_body.error.message);
        }
      }
      return response_body;
    },
    swrOptions
  );

  const state =
    !error && !data
      ? LOADING
      : isValidating
      ? VALIDATING
      : error
      ? ERROR
      : READY;

  return { state, data, error, mutate };
}

/**
 * Executes a POST request.
 *
 * @template T the type of the query result.
 * @param api the `FaunaAPI` to execute the query.
 * @param expr the query expression to execute.
 * @param swrOptions options for fine-tuning SWR.
 */
export async function post<T>(
  api: FaunaAPI,
  path: string,
  body: string
): Promise<{ status: number; body: T }> {
  const res = await fetch(api.endpoint + path, {
    headers: {
      Authorization: "Bearer " + api.secret,
    },
    method: "POST",
    body: body,
  });

  const response_body = await res.json();

  if (!res.ok) {
    throw new Error(response_body.error.message);
  }

  return {
    status: res.status,
    body: response_body,
  };
}

/**
 * Like `post`, but returns an object instead of
 * throwing an exception for non-2XX responses.
 */
export async function postOk<T>(
  api: FaunaAPI,
  path: string,
  body: string
): Promise<{ ok: boolean; status: number; body: T }> {
  const res = await fetch(api.endpoint + path, {
    headers: {
      Authorization: "Bearer " + api.secret,
    },
    method: "POST",
    body: body,
  });

  const response_body = await res.json();

  return {
    ok: res.ok,
    status: res.status,
    body: response_body,
  };
}

export async function postFiles(
  api: FaunaAPI,
  path: string,
  files: Array<{ file: string; content: string }>
): Promise<{ status: number; body: any }> {
  const formData = new FormData();

  for (const file of files) {
    formData.append(file.file, file.content);
  }

  const res = await fetch(api.endpoint + path, {
    headers: {
      Authorization: `Bearer ${api.secret}`,
    },
    method: "POST",
    body: formData,
  });

  const response_body = await res.json();

  if (!res.ok) {
    throw new Error(response_body.error.message);
  }

  return {
    status: res.status,
    body: response_body,
  };
}

export async function httpDelete<T>(
  api: FaunaAPI,
  path: string
): Promise<{ status: number; body: T }> {
  const res = await fetch(api.endpoint + path, {
    headers: {
      Authorization: "Bearer " + api.secret,
    },
    method: "DELETE",
  });

  const response_body = await res.json();

  if (!res.ok) {
    throw new Error(response_body.error.message);
  }

  return {
    status: res.status,
    body: response_body,
  };
}
