import { useFaunaUrl } from "modules/hooks/useFaunaURL";
import Loading from "pages/Loading/Loading";
import ErrorPage from "pages/Error/ErrorPage";
import { Validator, RouteValidationStatus } from "./validators";

export const Validate = ({
  children,
  validator,
}: {
  children: JSX.Element;
  validator: Validator;
}) => {
  const params = useFaunaUrl();

  // if no validator function was given, render the child component
  if (!validator) return children;

  const validationResponse = validator(params);

  switch (validationResponse.status) {
    case RouteValidationStatus.NOT_FOUND:
      return <ErrorPage {...validationResponse} />;
    case RouteValidationStatus.INTERNAL_ERROR:
      return <ErrorPage {...validationResponse} />;
    case RouteValidationStatus.PERMISSION_DENIED:
      return <ErrorPage {...validationResponse} />;
    case RouteValidationStatus.SUCCESS:
      return children;
    case RouteValidationStatus.PENDING:
      return <Loading />;
    default:
      return children;
  }
};
