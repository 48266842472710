import { RumPublicApi } from "@datadog/browser-rum-core";
import { getSession, Session } from "../session";

/**
 * Emit an error to Datadog RUM if it's available, otherwise log to console.
 */
const emitError = (error: Error, rum?: RumPublicApi) => {
  if (rum) {
    rum.addError(error);
  } else {
    console.error(error);
  }
};

/**
 * Get all region secrets from the session.
 */
const secretsFromSession = (session: Session) => {
  return [
    session.secret,
    ...Object.values(session.regionGroups).map((rg) => rg.secret),
  ];
};

type CreateAuthenticationTypeInterceptParams = {
  fetch: typeof fetch;
  secrets: string[];
  rum?: RumPublicApi;
};

/**
 * Create a fetch interceptor that will emit a RUM action if any deprecated
 * authentication mechanism are used.
 */
const createAuthenticationTypeIntercept = ({
  fetch,
  secrets,
  rum,
}: CreateAuthenticationTypeInterceptParams) => {
  return function monitoredFetch(
    input: RequestInfo | URL,
    init?: RequestInit
  ): Promise<Response> {
    // To do the checks, actually use the Headers object to avoid needing to care about
    // casing of the header names.
    const headers = new Headers(
      (init?.headers as Record<string, string>) || {}
    );

    // Wrap the logic in a try/catch to avoid breaking the original fetch call.
    try {
      // If we have an authorization header, check if it's a key we care about.
      // Because scoped secrets are prefixed with the region secret, we use startsWith.
      const maybeAuthorization = headers.get("Authorization");
      if (maybeAuthorization !== null) {
        const parts = maybeAuthorization.split(" ");
        // Check if it's a Bearer token and has the expected format
        if (parts.length === 2 && parts[0].toLowerCase() === "bearer") {
          const token = parts[1];
          if (secrets.find((s) => token.startsWith(s))) {
            if (rum) {
              rum.addAction("sessionKeyUsed");
            } else {
              emitError(new Error("Key used"), rum);
            }
          }
        }
      }

      // Old-style secret header used in some requests. We don't want
      // any of these to be used.
      const maybeSecret = headers.get("Secret");
      if (maybeSecret !== null) {
        if (rum) {
          rum.addAction("secretHeaderUsed");
        } else {
          emitError(new Error("Secret header used"), rum);
        }
      }
    } catch (e) {
      emitError(e as Error, rum);
    }

    // And finally do the original fetch call
    return fetch(input, init);
  };
};

type RestoreFetchFn = () => void;

type UseAuthenticationTypeInterceptParams = {
  target: {
    fetch: typeof fetch;
  };
  rum?: RumPublicApi;
};

/**
 * Initialize a fetch interceptor that will emit a RUM action if a session key is used.
 */
export const useAuthenticationTypeIntercept = ({
  target,
  rum,
}: UseAuthenticationTypeInterceptParams): RestoreFetchFn => {
  const session = getSession();

  if (!session) {
    emitError(new Error("No session found"), rum);
    return () => {};
  }

  if (!target.fetch) {
    emitError(new Error("target.fetch is undefined"), rum);
    return () => {};
  }

  const secrets = secretsFromSession(session);
  const targetFetch = target.fetch;
  target.fetch = createAuthenticationTypeIntercept({
    fetch: targetFetch,
    secrets,
    rum,
  });

  return () => {
    target.fetch = targetFetch;
  };
};
