import {
  createDatabaseKey,
  CreateDatabaseKeyOutput,
} from "../../api/frontdoor/databases";
import {
  createStorageMapFromSession,
  DatabaseKeyStoreMap,
  isStorageAvailable,
  DatabaseKeyEntry,
  MapStore,
} from "./stores";

/** How long before a key expires should we say it's expired */
const TTL_BUFFER_MS = 10000; // 10 seconds

/** How long before a key should expire */
const TTL_DEFAULT_MS = 1000 * 60 * 15; // 15 minutes

export type DatabaseKeyStoreOptions = {
  /** A ttl for the keys in milliseconds. */
  ttlMs: number;

  /** How long before a key expires to say it's expired in milliseconds. */
  ttlMsExpirationBuffer?: number;

  /** A map of key names to their entries. */
  keys?: DatabaseKeyStoreMap;
};

export type KeyOperationParameters = {
  /** The role for the key. This is always `admin` for now as we don't support other roles. */
  role: string;

  /** The path for the key. */
  path: string;
};

/**
 * A store for database keys. DO NOT USE THIS DIRECTLY. Instead, use the instance
 * returned from `getDatabaseKeyStore()`. This class is exported for testing purposes.
 *
 * Since this class is not expected to have any subscribers (i.e., push when changes happen),
 * it's just a simple wrapper around a map. The default implementation uses an in-memory map,
 * but it could be extended to support persistence or other storage mechanisms--like `localStorage`.
 *
 * @example
 * ```typescript
 * const store = getDatabaseKeyStore();
 * const key = await store.getOrCreate({ role: "admin", path: "region/some/path" });
 * const result = await api.query(someFQL, { secret: key });
 * ```
 */
export class DatabaseKeyStore {
  /** A map of key names to their entries. */
  private keys: DatabaseKeyStoreMap;

  /** The internal store isn't async safe, so we store pending keys here so we don't create duplicates. */
  private pendingKeyEntries: Record<string, Promise<string>>;

  /** The default TTL for keys in milliseconds. This is sent to Fauna as a unix timestamp ttlMs in the future. */
  private ttlMs: number;

  /** How long before a key expires to say it's expired in milliseconds. */
  private ttlMsExpirationBuffer: number;

  constructor({
    ttlMs,
    ttlMsExpirationBuffer = TTL_BUFFER_MS,
    keys = new MapStore(),
  }: DatabaseKeyStoreOptions) {
    this.keys = keys;
    this.ttlMs = ttlMs;
    this.ttlMsExpirationBuffer = ttlMsExpirationBuffer;
    this.pendingKeyEntries = {};
  }

  /**
   * Returns the time at which a key should expire. This will be the actual TTL
   * minus a buffer to prevent needing to make multiple calls to Fauna.
   * (i.e., we want to avoid the case where a key is already expired and need to
   * request a new one immediately).
   */
  private getKeyExpiration(): number {
    return Date.now() + this.ttlMs;
  }

  /**
   * Returns true if the key has expired.
   */
  private isExpired(key: DatabaseKeyEntry): boolean {
    return key.expiresAt - this.ttlMsExpirationBuffer < Date.now();
  }

  /**
   * Generates a key name from the parameters. This is internal to the store and
   * bounded by the DatabaseKeyStore instance.
   */
  private generateKeyName({ role, path }: KeyOperationParameters): string {
    return `${path}:${role}`;
  }

  /**
   * Returns a database key name for a given key.
   */
  private generateDatabaseKeyName(): string {
    return `System-generated dashboard key`;
  }

  /**
   * Returns KeyOperationParameters for a given generated key. This is used for
   * reverse lookups when refreshing keys.
   */
  private getKeyOperationParameters(keyName: string): KeyOperationParameters {
    const [path, role] = keyName.split(":");
    return { role, path };
  }

  private async createDatabaseKey({
    role,
    path,
    expiresAt,
  }: KeyOperationParameters & {
    expiresAt: number;
  }): Promise<CreateDatabaseKeyOutput> {
    return createDatabaseKey({
      name: this.generateDatabaseKeyName(),
      role,
      path,
      ttl: new Date(expiresAt).toISOString(),
    });
  }

  /**
   * Creates a new database key and stores it in the map.
   *
   * To avoid creating duplicate keys, we use pendingKeys as a de-dupe cache.
   * As soon as a create is called, we place a promise in pendingKeys for that
   * keyName. If we later call create with the same keyName, we can return the
   * promise instead of creating a new key. Once the promise resolves, we delete
   * the promise from pendingKeys.
   */
  private async create({
    role,
    path,
  }: KeyOperationParameters): Promise<string> {
    const keyName = this.generateKeyName({ role, path });
    const expiresAt = this.getKeyExpiration();

    // If we already have a pending key, return it
    if (this.pendingKeyEntries[keyName] !== undefined) {
      return this.pendingKeyEntries[keyName];
    }

    // Otherwise, create a new pending key
    const pendingKeyEntry = this.createDatabaseKey({ role, path, expiresAt })
      .then(({ secret }) => {
        const keyEntry = { databaseKey: secret, expiresAt };
        this.keys.set(keyName, keyEntry);
        return keyEntry.databaseKey;
      })
      .finally(() => {
        // Always delete the pending key at the end of the promise chain
        delete this.pendingKeyEntries[keyName];
      });

    this.pendingKeyEntries[keyName] = pendingKeyEntry;

    return pendingKeyEntry;
  }

  /**
   * Retrieves a database key from the store. If the key doesn't exist
   * or if it has maybe expired, it returns undefined.
   */
  get({ role, path }: KeyOperationParameters): string | undefined {
    const keyName = this.generateKeyName({ role, path });
    const key = this.keys.get(keyName);

    // Key doesn't exist
    if (!key) {
      return undefined;
    }

    // If we consider this key expired, delete it and return undefined
    if (this.isExpired(key)) {
      this.delete({ role, path });
      return undefined;
    }

    return key.databaseKey;
  }

  /**
   * Retrieves a database key from the store. If the key doesn't exist or it has expired,
   * it creates a new key.
   */
  async getOrCreate({ role, path }: KeyOperationParameters): Promise<string> {
    const key = this.get({ role, path });

    // If we have a key, return it
    if (key) {
      return key;
    }

    // Otherwise, create a new key
    return this.create({ role, path });
  }

  /**
   * Refreshes a key by deleting it and then recreating it.
   */
  async refresh(databaseKey: string): Promise<string> {
    const fullKey = this.keys.getByDatabaseKey(databaseKey);

    // No key? Blow up. We can only refresh keys we know about.
    if (!fullKey) {
      throw new Error("Unable to refresh key: no key found");
    }

    const { role, path } = this.getKeyOperationParameters(fullKey);
    this.delete({ role, path });

    return this.getOrCreate({ role, path });
  }

  /**
   * Deletes a database key from the map. Returns true if a key was deleted, false
   * if no key was found
   */
  delete({ role, path }: KeyOperationParameters): boolean {
    const keyName = this.generateKeyName({ role, path });
    delete this.pendingKeyEntries[keyName];
    return this.keys.delete(keyName);
  }

  /**
   * Clears the store.
   */
  clear() {
    this.keys.clear();
    this.pendingKeyEntries = {};
  }
}

/**
 * Creates a new DatabaseKeyStore. If session storage is available, it will use
 * that to store the keys.
 */
export const createNewDatabaseKeyStore = () => {
  // in memory map is the default if the current environment doesn't support sessionStorage
  let keys: DatabaseKeyStoreMap = new MapStore();

  // if session storage is available, use it to store the keys
  if (isStorageAvailable(sessionStorage)) {
    keys = createStorageMapFromSession();
  }

  return new DatabaseKeyStore({
    ttlMs: TTL_DEFAULT_MS,
    keys,
  });
};

// Export a singleton instance
const databaseKeyStore = createNewDatabaseKeyStore();

/**
 * Returns a singleton ("global") instance of `DatabaseKeyStore`.
 */
export const getDatabaseKeyStore = () => databaseKeyStore;
