import { create } from "zustand";

// Account-level notifications

interface Notification {
  asComponent: () => JSX.Element;
}

interface NotificationActions {
  put: (notification: Notification) => void;
  remove: () => void;
}

export const useNotificationStore = create<
  { notification?: Notification } & NotificationActions
>((set) => ({
  put: (notification: Notification) => {
    set((prev) => {
      return {
        ...prev,
        notification: notification,
      };
    });
  },
  remove: () => {
    set((prev) => {
      return {
        ...prev,
        notification: undefined,
      };
    });
  },
}));
