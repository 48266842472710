import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";

let isGAInitialized = false;

type InitializeAnalyticsInput = {
  gtmId?: string;
  gaId?: string;
};

export const initializeAnalytics = ({
  gtmId,
  gaId,
}: InitializeAnalyticsInput) => {
  if (gtmId) {
    TagManager.initialize({ gtmId });
  }

  if (gaId) {
    ReactGA.initialize(gaId);
    isGAInitialized = true;
  }
};

export const useAnalytics = () => {
  // Google Analytics example shared in React Router docs
  // https://reactrouter.com/en/6.15.0/hooks/use-location
  const location = useLocation();

  useEffect(() => {
    if (!isGAInitialized) return;

    ReactGA.send({
      hitType: "pageview",
      page: removePII(location.pathname) + location.search,
    });
  }, [location]);
};

export const removePII = (path: string): string => {
  // remove any invite tokens from the path
  const regex_invites = new RegExp(
    "^(/invites)" + // invites path (captured)
      "(?:/.*)$" // everything after (NOT captured)
  );

  // remove the database part of the path
  const regex_security = new RegExp(
    "^(/resources/security)" + // security path (captured)
      "(?:/.*)$" // everything after (NOT captured)
  );

  return path.replace(regex_invites, "$1").replace(regex_security, "$1");
};
