import env from "src/env";
import { getSession } from "modules/session";
import { FeatureFlag } from "./featureFlags";

export function getOldDashboardUrl() {
  const session = getSession();
  if (session?.feature_flags?.[FeatureFlag.V10_LAUNCHED]) {
    return `${env.VITE_OLD_DASHBOARD_URL.substring(
      0,
      8
    )}v4.${env.VITE_OLD_DASHBOARD_URL.substring(8)}`;
  }
  return env.VITE_OLD_DASHBOARD_URL;
}

export function getDocsUrl() {
  return "https://docs.fauna.com/fauna/current";
}

export function getDriversUrl() {
  return `${getDocsUrl()}/build/drivers/`;
}

export const communityUrl = "https://community-invite.fauna.com/";

export const statusUrl = "https://status.fauna.com/";

export const supportUrl = "https://support.fauna.com/hc/en-us";

export const toolsUrl = "https://docs.fauna.com/fauna/current/tools/";
