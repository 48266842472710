import { useContext } from "react";
import {
  PermissionDomain,
  PermissionLevel,
  PlanFeatureLookup,
} from "modules/api/auth-service/permissions";
import { UserIdentityContext } from "modules/context/UserIdentityContext";
import { datadogRum } from "@datadog/browser-rum-slim";

/**
 * Any component or function can use this hook to check if a user has certain access rights to
 *  a feature.
 * @param domain The domain wherein the feature falls
 * @param requestedPermissionLevel Which permissions the calling component or function
 *  (read or write) requires the user to have
 * @returns PermissionResponse
 */
const usePermissions = (
  domain: PermissionDomain,
  requestedPermission: PermissionLevel
): {
  hasPermission: boolean;
  planHasFeature?: boolean;
  loading?: boolean;
} => {
  const userIdentity = useContext(UserIdentityContext);
  // handle case where synced is false, should show banner and old? permissions
  const permissionsArray = [];
  if (requestedPermission === PermissionLevel.write) {
    permissionsArray.push(
      PermissionLevel.create,
      PermissionLevel.update,
      PermissionLevel.delete
    );
  } else {
    permissionsArray.push(requestedPermission);
  }

  let viewArray = userIdentity.view_controls?.["*"] ?? [];
  if (userIdentity.view_controls?.[domain] !== undefined) {
    viewArray = userIdentity.view_controls[domain] as PermissionLevel[];
  }

  const features = [
    PermissionDomain.QUERYLOG,
    PermissionDomain.BACKUP_RESTORE,
    PermissionDomain.INTEGRATIONS,
  ];

  // If the plan lookup map does not include the users plan, emit a metric.
  // If this is the case a user may not have access to all the features they should.
  if (PlanFeatureLookup[userIdentity.plan] === undefined) {
    datadogRum?.addAction("unknownPlan", {
      plan: userIdentity.plan,
    });
  }

  const planHasFeature = features.includes(domain)
    ? (PlanFeatureLookup[userIdentity.plan] ?? []).includes(domain)
    : undefined;

  return {
    loading: userIdentity.loading,
    hasPermission: permissionsArray.every((p) => viewArray.includes(p)),
    planHasFeature,
  };
};

// TODO: instead of x amount of hooks for x variations of permissions that a component may need,
//  find a way to inject permissions into a component. This will allow us to have a single hook
//  that can be used by any component that needs to check permissions. Basically store the permissions
//  in a config that maps the different components and what access they need.

export default usePermissions;
