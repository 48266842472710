import { useEffect, useState } from "react";

export type BrowserSupportType = {
  name?: string;
  version?: number;
  isSupported: boolean;
  upgradeLink?: string;
};

const supportedBrowsers: {
  [key: string]: { version: number; upgradeLink: string };
} = {
  Safari: {
    version: 12.1,
    upgradeLink: "https://support.apple.com/en-us/HT204416",
  },
  Chrome: {
    version: 69,
    upgradeLink:
      "https://support.google.com/chrome/answer/95414?hl=en&co=GENIE.Platform%3DDesktop",
  },
  Edge: {
    version: 79,
    upgradeLink:
      "https://support.microsoft.com/en-us/topic/update-to-the-new-microsoft-edge-182d0668-e3f0-49da-b8bb-db5675245dc2",
  },
  Firefox: {
    version: 62,
    upgradeLink:
      "https://support.mozilla.org/en-US/kb/update-firefox-latest-release",
  },
};

/**
 * hook that components can use to conditionally render something if the
 * browser is not supported
 * @returns BrowserSupportType
 */
export const useBrowserSupport = () => {
  const [browserInfo, setBrowserInfo] = useState<BrowserSupportType>({
    isSupported: true,
    upgradeLink: undefined,
  });

  useEffect(() => {
    try {
      const info = get_browser();
      setBrowserInfo(info);
    } catch {
      setBrowserInfo({ isSupported: true, upgradeLink: undefined });
    }
  }, []);
  return browserInfo;
};

/**
 * Parse browser name and version number from the window
 * @returns BrowserSupportType
 */
export function get_browser(): BrowserSupportType {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return isSupported({ name: "IE", version: tem[1] || "" });
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return isSupported({ name: "Opera", version: tem[1] });
    }
  }
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    tem = ua.match(/Edge\/(\d+)/);
    if (tem != null) {
      return isSupported({ name: "Edge", version: tem[1] });
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return isSupported({
    name: M[0],
    version: M[1],
  });
}

/**
 * Given a browser name and version, check the hardcoded mapping of supported
 * browsers to verify support. If not supported, provide upgradeLink
 * @param browser
 * @returns BrowserSupportType
 */
function isSupported(browser: { name: string; version: string }) {
  let supported = true;
  let upgradeLink = "";
  const versionNum = parseFloat(browser.version);
  if (supportedBrowsers[browser.name]) {
    upgradeLink = supportedBrowsers[browser.name].upgradeLink;
    supported = versionNum >= supportedBrowsers[browser.name].version;
  }

  return {
    name: browser.name,
    version: versionNum,
    isSupported: supported,
    upgradeLink,
  };
}
