import { Validate } from "./Validate";
import loadable from "@loadable/component";
import {
  Route,
  Routes,
  Outlet,
  Navigate as _Navigate,
  useLocation,
  NavigateProps,
} from "react-router-dom";
import {
  useAcceptInviteRouteValidation,
  useBillingRouteValidation,
  useReadAccountRouteValidation,
} from "./validators";
import NavLayout from "../layouts/NavLayout";
import ProtectedRoute from "./ProtectedRoute";
import LoggedRoute from "./LoggedRoute";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
import Explorer from "pages/Resources/Explorer/Explorer";
import { useAnalytics } from "modules/analytics/analytics";

import { Layout } from "frontend-components";

// TODO: look into https://github.com/facebook/react/issues/14299#issuecomment-730192566
//  regarding lazy loading routes and unnecessary re-renders

const Authorize = loadable(() => import("pages/Authorize"));
const Login = loadable(() => import("pages/Login"));
const Register = loadable(() => import("pages/Register"));
const AcceptInvite = loadable(() => import("pages/Invite"));
const NewResources = loadable(() => import("pages/Resources"));
const Logs = loadable(() => import("pages/Resources/Logs"));
const Integrations = loadable(() => import("pages/Resources/Integrations"));
const Backups = loadable(() => import("pages/Resources/Backups"));
const NotFound = loadable(() => import("pages/Error/NotFound"));
const PersonalSettings = loadable(() => import("pages/PersonalSettings"));
const AccountSettings = loadable(() => import("pages/AccountSettings"));
const Billing = loadable(() => import("pages/AccountSettings/Billing"));
const TeamManagement = loadable(
  () => import("pages/AccountSettings/TeamManagement")
);
const ContactInfo = loadable(() => import("pages/AccountSettings/ContactInfo"));
const Dashboard = loadable(() => import("pages/Resources/Home"));
const AccountKeys = loadable(() => import("pages/AccountSettings/AccountKeys"));
const OAuthApplications = loadable(
  () => import("pages/AccountSettings/OAuthApplications")
);
const OAuthCallback = loadable(() => import("pages/OAuthCallback"));
const OAuthDatadog = loadable(() => import("pages/OAuthIntegration"));
const OAuthRedirect = loadable(() => import("pages/OAuthRedirect"));
const ForgotPassword = loadable(() => import("pages/ForgotPassword"));
const ResetPassword = loadable(() => import("pages/ResetPassword"));
const AlwaysError = loadable(() => import("pages/Error/AlwaysError"));
const VercelIntegration = loadable(() => import("pages/VercelIntegration"));
const Verify = loadable(() => import("pages/EmailVerification/Verify"));
const NewVerifyToken = loadable(
  () => import("pages/EmailVerification/NewVerifyToken")
);

export const DashboardRoutes = () => {
  /**
   * Send google analytics pageview events when the route changes.
   *
   * @warning Any routes or query params that include "Personally Identifiable
   * Information" (PII) must be accounted for before sending to Google
   * Analytics.
   *
   * @see {@link ../modules/analytics/analytics.ts}
   */
  useAnalytics();

  const { search } = useLocation();

  // Preserve query params when redirecting routes
  const Navigate = (props: NavigateProps) => {
    return <_Navigate {...props} to={props.to + search} />;
  };

  return (
    <Routes>
      <Route
        path="register"
        element={
          <LoggedRoute>
            <Register />
          </LoggedRoute>
        }
      />
      <Route
        path="/accounts/register"
        element={
          <LoggedRoute>
            <Navigate to="/register" replace />
          </LoggedRoute>
        }
      />
      <Route
        path="/accounts/verify"
        element={
          <LoggedRoute>
            <AuthLayout showMessaging={true} form={<Outlet />} />
          </LoggedRoute>
        }
      >
        <Route
          path=""
          element={
            <LoggedRoute>
              <Verify />
            </LoggedRoute>
          }
        />
        <Route
          path="new"
          element={
            <LoggedRoute>
              <NewVerifyToken />
            </LoggedRoute>
          }
        />
      </Route>
      <Route
        path="authorize"
        element={
          <LoggedRoute>
            <Authorize />
          </LoggedRoute>
        }
      />
      <Route
        path="login"
        element={
          <LoggedRoute>
            <Login />
          </LoggedRoute>
        }
      />
      <Route path="test-errors" element={<AlwaysError />} />
      <Route
        path="/accounts/login"
        element={
          <LoggedRoute>
            <Navigate to="/login" replace />
          </LoggedRoute>
        }
      />
      <Route
        path="forgot-password"
        element={
          <LoggedRoute>
            <ForgotPassword />
          </LoggedRoute>
        }
      />
      {["reset-password", "auth/reset-password"].map((path) => {
        // Handle redirects from the old dashboard on 'auth/reset-password'
        return (
          <Route
            key={path}
            path={path}
            element={
              <LoggedRoute>
                <ResetPassword />
              </LoggedRoute>
            }
          />
        );
      })}
      <Route path="/auth/oauth/callback" element={<OAuthCallback />}></Route>
      <Route
        path="/auth/oauth/datadog"
        element={
          <ProtectedRoute>
            <OAuthDatadog />
          </ProtectedRoute>
        }
      />
      <Route path="/auth/oauth/redirect" element={<OAuthRedirect />} />
      <Route
        path="invites/:inviteToken/*"
        element={
          <Layout
            width="screen"
            height="screen"
            alignItems="center"
            justifyContent="center"
          >
            <Validate validator={useAcceptInviteRouteValidation}>
              <AcceptInvite />
            </Validate>
          </Layout>
        }
      />
      <Route
        path="/addons/authorize"
        element={
          <ProtectedRoute>
            <Layout
              width="screen"
              height="screen"
              alignItems="center"
              justifyContent="center"
            >
              <VercelIntegration />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/resources"
        element={<Navigate to="/resources/home" replace />}
      />
      <Route path="/" element={<Navigate to="/resources/home" replace />} />
      <Route
        path="resources"
        element={
          <ProtectedRoute>
            <NavLayout>
              <NewResources>
                <Outlet />
              </NewResources>
            </NavLayout>
          </ProtectedRoute>
        }
      >
        {Explorer}
        <Route path="home" element={<Dashboard />} />
        <Route path="backups" element={<Backups />} />
        <Route path="logs" element={<Logs />} />
        <Route path="integrations" element={<Integrations />} />
        <Route path="personal-settings" element={<PersonalSettings />} />
        <Route
          path="account-settings"
          element={
            <AccountSettings>
              <Outlet />
            </AccountSettings>
          }
        >
          <Route
            path="contact-info"
            element={
              <Validate validator={useReadAccountRouteValidation}>
                <ContactInfo />
              </Validate>
            }
          />
          <Route
            path="billing"
            element={
              <Validate validator={useBillingRouteValidation}>
                <Billing />
              </Validate>
            }
          />
          <Route path="team-management" element={<TeamManagement />} />
          <Route path="account-keys" element={<AccountKeys />} />
          <Route path="oauth-applications" element={<OAuthApplications />} />
        </Route>
      </Route>
      {
        // Redirect existing links to account-settings to the new path
        // TODO: Remove after links in v4 dashboard are updated
      }
      <Route
        path="account-settings"
        element={<Navigate to="/resources/account-settings" replace />}
      >
        <Route
          path="contact-info"
          element={
            <Navigate to="/resources/account-settings/contact-info" replace />
          }
        />
        <Route
          path="billing"
          element={
            <Navigate to="/resources/account-settings/billing" replace />
          }
        />
        <Route
          path="team-management"
          element={
            <Navigate
              to="/resources/account-settings/team-management"
              replace
            />
          }
        />
        <Route
          path="account-keys"
          element={
            <Navigate to="/resources/account-settings/account-keys" replace />
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <Layout
            width="screen"
            height="screen"
            alignItems="center"
            justifyContent="center"
          >
            <NotFound />
          </Layout>
        }
      />
    </Routes>
  );
};

export default DashboardRoutes;
