import env from "src/env";
import { Session } from ".";
import * as cookies from "../cookies";

type SessionCookieContents = {
  data: Session;
  strategy: string;
  provider?: string;
};

const SESSION_COOKIE_NAME = "session";

const SESSION_COOKIE_CONFIG: cookies.CookieConfig = {
  secure: !(env.VITE_IS_LOCAL == "true"),
  expires: 30, // days
  domain: cookies.COOKIE_DOMAIN,
};

const get = (): SessionCookieContents => cookies.get(SESSION_COOKIE_NAME);

const remove = (): void => {
  cookies.remove(SESSION_COOKIE_NAME);
};

const save = (contents: SessionCookieContents): void =>
  cookies.save(SESSION_COOKIE_NAME, contents, SESSION_COOKIE_CONFIG);

const exists = (): boolean => cookies.exists(SESSION_COOKIE_NAME);

const SessionCookie = {
  get,
  remove,
  save,
  exists,
  SESSION_COOKIE_NAME,
  SESSION_COOKIE_CONFIG,
};

export default SessionCookie;
