import { KeyedMutator } from "swr";

/** Default options for SWR cache. */
export const DEFAULT_SWR_OPTIONS = { dedupingInterval: 20000 };

/** The possible states that a `SWRCache` can be in. */
export enum SWRCacheState {
  /** No cached data; data is loading. */
  LOADING,
  /** A new page of data is loading. Cached data represents the current page. */
  PAGING,
  /** Verifying whether the currently cached data is still valid. */
  VALIDATING,
  /** The latest attempt to load data failed. Cached data may still exist. */
  ERROR,
  /** Data is loaded and valid. */
  READY,
}

/**
 * A generic cache that wraps SWR. It has a field, `data`, which contains the
 * currently cached data. The type `T` of `data` may differ from the type `R`
 * returned by the SWR iterface.
 * @template Data the type of the data.
 * @template R the type of the response.
 * @template Err the type of the error.
 */
export interface SWRCache<Data, R = Data, Err = unknown> {
  /** The current state of this `Cache` */
  state: SWRCacheState;

  /** The current cached data. */
  data?: Data;

  /** The current error, if any. */
  error?: Err;

  /** Revalidates the current cached data. */
  revalidate?: () => Promise<boolean>;

  /** Mutates the cached data and (optionally) revalidates. */
  mutate?: (
    data?: R | Promise<R> | KeyedMutator<R>,
    shouldRevalidate?: boolean
  ) => Promise<R | undefined>;
}
