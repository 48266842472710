import { Link, Button } from "frontend-components";

/**
 * Links the user to the creating a new request to the support team.
 * @param options
 * @param options.linkText the text for the link defaults to "Support Team"
 * @returns a link component to the support team
 */
const SupportTeamLink = ({
  text = "Support Team",
  type = "link",
}: {
  text?: string;
  type?: "link" | "button";
}) => {
  const link = "https://support.fauna.com/hc/en-us/requests/new";

  if (type === "link") {
    return (
      <Link
        href="https://support.fauna.com/hc/en-us/requests/new"
        external={true}
      >
        {text}
      </Link>
    );
  }

  if (type === "button") {
    return <Button onClick={() => window.open(link)}>{text}</Button>;
  }

  return <></>;
};

export default SupportTeamLink;
