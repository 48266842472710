// Core defines all database names as matching the following regex:
// `[;@+$\-_.!~%\w]+`
//
// Valid URL path segment characters according to RFC2396
// `[;:@&=+$,\-_.!~*'()%\w]+`
// minus chars & = ( ) ' ,
//
// This means we only have a few characters left to use:
// - `:`
// - `/`
// - `*`
//
// Using these characters, we can have the following format:
//
// ```
// dashboard.fauna.com/explorer/resources/<region>/<db:db...>/<resource>
// ```
//
// I've split the db name using `:`, so that we don't need to deal with trailing
// slashes, and so that it fits into a single elementin react router.
//
// The resource within that DB is defined by query parameters, which are all
// optional.
//
// For example:
//
// ```
// # for the db `foo/bar` in the `us` region:
// dashboard.fauna.com/explorer/resources/us/foo:bar
//
// # for the list of collections in the db `foo/bar` in the `us` region:
// dashboard.fauna.com/explorer/resources/us/foo:bar/collections
//
// # for the schema editor of collection `User` in the db `foo/bar` in the `us` region:
// dashboard.fauna.com/explorer/resources/us/foo:bar/collections/User
//
// # for all the docs in `User` in the db `foo/bar` in the `us` region:
// dashboard.fauna.com/explorer/resources/us/foo:bar/collections/User/documents
//
// # for doc `User.byId("1234")` in the db `foo/bar` in the `us` region:
// dashboard.fauna.com/explorer/resources/us/foo:bar/collections/User/documents/1234
// ```

import { Region } from "modules/region";
import { DatabasePath } from "modules/utils/path";

export class ResourceParseError extends Error {}

/**
 * A schema collection page, like the list of collections or functions.
 *
 * example: `collection`, `function`
 */
export enum SchemaKind {
  Collection = "collections",
  Function = "functions",
  Role = "roles",
  Key = "keys",
  AccessProvider = "access_providers",
}

export const BASE_URL = "/resources/explorer/";
export const COLLECTION_URI_KEY = SchemaKind.Collection as string;
export const FUNCTION_URI_KEY = SchemaKind.Function as string;
export const ROLE_URI_KEY = SchemaKind.Role as string;
export const KEY_URI_KEY = SchemaKind.Key as string;
export const ACCESS_PROVIDER_URI_KEY = SchemaKind.AccessProvider as string;
export const DOCUMENT_URI_KEY = "documents";
export const INDEX_URI_KEY = "indexes";
export const SCHEMA_URI_KEY = "schema";

export type FromURLParams = {
  region?: string;
  db?: string;
  resource?: string;
  name?: string;
};

/**
 * Stores a database path and a resource key. This represents the full URL to
 * the current resource in the explorer.
 */
export class DatabaseResource {
  db: DatabasePath;
  resource?: ResourceKey;

  constructor(db: DatabasePath, resource?: ResourceKey) {
    this.db = db;
    this.resource = resource;
  }

  /**
   * Parses segments from react router into a DatabaseResource.
   *
   * If parsing fails, this returns undefined.
   */
  static fromURL(params: FromURLParams): DatabaseResource {
    return DatabaseResource.fromURLOrError(params);
  }

  /**
   * Parses segments from react router into a DatabaseResource.
   *
   * If parsing fails, this throws a ResourceParseError.
   */
  static fromURLOrError(params: FromURLParams): DatabaseResource {
    const { region, db, name, resource } = params;

    if (region === undefined) {
      throw new ResourceParseError(`Invalid region: ${region}`);
    }

    const rg = Region.get(region);
    if (rg === undefined) {
      throw new ResourceParseError(`Invalid region: ${region}`);
    }

    const dbPath = new DatabasePath(db?.split(":") ?? [], rg);

    if (resource === undefined) {
      return new DatabaseResource(dbPath);
    } else {
      const res = parseResource(resource, name);
      return new DatabaseResource(dbPath, res);
    }
  }

  /**
   * Converts this database resource back to the URL. This is the opposite of
   * `parse`.
   *
   * This won't include query parameters. Use `toQueryParams` to get an object
   * with all the query parameters needed.
   *
   * TODO: add more options to this method to return a url that's scoped
   * to a subset of the provided resource path. i.e. generate URL to the
   * Database only even if a resource is provided.
   */
  toURL(options?: { includeBaseUrl?: boolean }): string {
    const includeBaseUrl = options?.includeBaseUrl ?? true;
    const segments = [
      this.db.regionGroup.regionPrefix,
      this.db.namePath.map((name) => encodeURIComponent(name)).join(":"),
    ];

    if (this.resource !== undefined) {
      segments.push(
        ...(resourceUrlOrder
          .map((k) => (this.resource as ResourceKey)[k])
          .filter((v) => !!v) as string[])
      );
    }

    if (includeBaseUrl) return BASE_URL + segments.join("/");
    return segments.join("/");
  }

  /**
   * Converts this database resource to a unique string key.
   */
  toKey(): string {
    const segments = [this.db.regionGroup.regionPrefix];

    if (!this.db.isRoot) {
      segments.push(
        this.db.namePath.map((name) => encodeURIComponent(name)).join(":")
      );
    }

    if (this.resource !== undefined) {
      segments.push(
        ...(resourceUrlOrder
          .map((k) => (this.resource as ResourceKey)[k])
          .filter((v) => !!v) as string[])
      );
    }

    return segments.join("/");
  }
}

const parseResource = (resource: string, name?: string): ResourceKey => {
  if (name === undefined) {
    return SchemaKey.parse(resource);
  } else {
    return SchemaItemKey.parse(resource, name);
  }
};

export interface ResourceKey {
  kind: SchemaKind;
  name?: string;
}

const resourceUrlOrder: (keyof ResourceKey)[] = ["kind", "name"];

export class SchemaKey implements ResourceKey {
  readonly kind: SchemaKind;

  constructor(kind: SchemaKind) {
    this.kind = kind;
  }

  static parse(segment: string) {
    if (
      [
        COLLECTION_URI_KEY,
        FUNCTION_URI_KEY,
        ROLE_URI_KEY,
        KEY_URI_KEY,
        ACCESS_PROVIDER_URI_KEY,
      ].includes(segment)
    ) {
      return new SchemaKey(segment as SchemaKind);
    } else {
      throw new ResourceParseError(`Invalid schema type: ${segment}`);
    }
  }
}

/**
 * The schema editor page for a single schema item.
 *
 * example: `collection:User`, `function:Foo`
 */
export class SchemaItemKey implements ResourceKey {
  readonly kind: SchemaKind;
  readonly name: string;

  constructor(kind: SchemaKind, name: string) {
    this.kind = kind;
    this.name = name;
  }

  static parse(kind_segment: string, name: string) {
    const key = SchemaKey.parse(kind_segment);
    return new SchemaItemKey(key.kind, name);
  }
}
