import { FetchError } from "modules/api/utils/fetch";

/**
 * The shape of an error returned by the auth service.
 */
interface AuthServiceError extends Error {
  code: string;
  message: string;
  metadata?: Record<string, any>;
}

/**
 * Checks if the given object is an {@link AuthServiceError}.
 *
 * @param maybeError The object to check.
 * @param response The response from the server. The response is used to make sure
 *  it's not a legit response from the server that just happens to have the same
 * shape.
 * @returns True if the object is an {@link AuthServiceError}, false otherwise.
 */
const isAuthServiceError = (
  maybeError: unknown,
  response: Response
): maybeError is AuthServiceError => {
  return (
    maybeError !== null &&
    typeof maybeError === "object" &&
    "code" in maybeError &&
    !response.ok
  );
};

/**
 * Checks if the given auth service response is a cooldown error. Cooldown errors
 * are returned as a string "Cooldown" as opposed to the typical AuthServiceError shape.
 *
 * @param maybeError The value to check.
 * @param response The response from the server.
 * @returns True if the value is a cooldown error, false otherwise.
 */
const isCooldownError = (maybeError: unknown, response: Response): boolean => {
  return (
    maybeError !== null &&
    typeof maybeError === "string" &&
    maybeError === "Cooldown" &&
    !response.ok
  );
};

/**
 * Checks if the given auth service response is an invalid password reset token error.
 * Invalid password reset token errors are returned as a string "Invalid password reset token provided"
 * as opposed to the typical AuthServiceError shape.
 *
 * @param maybeError The value to check.
 * @param response The response from the server.
 * @returns True if the value is an invalid password reset token error, false otherwise.
 */
const isInvalidPasswordResetTokenError = (
  maybeError: unknown,
  response: Response
): boolean => {
  return (
    maybeError !== null &&
    typeof maybeError === "string" &&
    maybeError === "Invalid password reset token provided" &&
    !response.ok
  );
};

// Options for commonResponseHandler
type CommonResponseHandlerOptions = {
  response: Response;
  expectResponseData?: boolean;
  defaultErrorMessage?: string;
};

/**
 * Overload when expectResponseData is true.
 * The return type should always be the expected type if expectResponseData is true.
 */
export async function commonResponseHandler<T>(
  opts: CommonResponseHandlerOptions & { expectResponseData?: true }
): Promise<T>;

/**
 * Overload when expectResponseData is false.
 * The return type should always be void if expectResponseData is false.
 */
export async function commonResponseHandler(
  opts: CommonResponseHandlerOptions & { expectResponseData: false }
): Promise<void>;

/**
 * Encapsulates error handling and response status checking for auth service
 * requests.
 *
 * @param options.response The response from the server.
 * @param options.expectResponseData Whether the response data is expected to be undefined.
 * @param options.defaultErrorMessage The default error message to use if we receive an unexpected response.
 * @returns The data from the response.
 * @throws A {@link FetchError} if we receive an error or the request fails unexpectedly.
 */
export async function commonResponseHandler<T>({
  response,
  expectResponseData = true,
  defaultErrorMessage = "Request failed",
}: CommonResponseHandlerOptions): Promise<T | undefined> {
  let dataStr: string | undefined;
  let dataJson: T | AuthServiceError | undefined;

  // Unpack the response data.
  try {
    // unpack the response as a string first because
    // certain errors, such as "Cooldown" errors, are returned as a string
    // rather than the typical AuthServiceError shape.
    dataStr = await response.text();
    dataJson = JSON.parse(dataStr);
  } catch (error) {
    if (expectResponseData) {
      console.error("Error parsing auth service response: ", error);
    }
  }

  // Check if the response is an auth service error.
  if (isAuthServiceError(dataJson, response)) {
    throw new FetchError({
      code: dataJson.code,
      message: dataJson.message,
      status: response.status,
      metadata: dataJson.metadata,
    });
  } else if (isCooldownError(dataStr, response)) {
    throw new FetchError({
      code: "cooldown",
      message: dataStr ?? "Too many requests",
      status: response.status,
    });
  } else if (isInvalidPasswordResetTokenError(dataStr, response)) {
    throw new FetchError({
      code: "invalid_password_reset_token",
      message: dataStr ?? "Invalid password reset token",
      status: response.status,
    });
  } else if ((!dataJson && expectResponseData) || !response.ok) {
    // If we were expecting data but didn't get any, or the response is bad for
    // any other reason, throw an error.
    throw new FetchError({
      status: response.status,
      message: defaultErrorMessage,
    });
  }

  return dataJson;
}
