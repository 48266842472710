import { Query, QueryFailure, fql } from "fauna";
import { tx } from "modules/language/translate";
import { Index } from "./collection";

export const stringifyIndexes = (indexes: { [key: string]: Index | null }) => {
  return Object.keys(indexes).map((indexName) => {
    if (indexes[indexName] === null) {
      return `"${indexName}": null`;
    } else {
      const terms = indexes[indexName]?.terms;
      const values = indexes[indexName]?.values;
      const hasTerms = !!terms && terms.length > 0;
      const hasValues = !!values && values.length > 0;
      const termString = `"terms": ${JSON.stringify(
        indexes[indexName]?.terms
      )}`;
      const valueString = `"values": ${JSON.stringify(
        indexes[indexName]?.values
      )}`;

      if (!hasTerms && !hasValues) {
        return `
        "${indexName}": {}`;
      } else if (hasValues && !hasTerms) {
        return `
          "${indexName}": {
            ${valueString}
          }`;
      } else if (hasTerms && !hasValues) {
        return `
          "${indexName}": {
            ${termString}
          }`;
      } else {
        return `
          "${indexName}": {
            ${termString},
            ${valueString}
          }`;
      }
    }
  });
};

/**
 * IMPORTANT NOTE: Please use FaunaErrorResponse in modules/api/fauna/error instead.
 *
 * Expand the error info we get over the wire to give components
 *  some options for what they want to display to the user.
 * @param error fqlx query failure
 * @returns {title, summary, description}
 */
export const getErrorFromFaunaResponse = (error: QueryFailure) => {
  return {
    //@ts-ignore
    title: error?.name ?? tx("errors.error"),
    summary: error?.summary ?? tx("errors.generic"),
    description:
      //@ts-ignore
      error?.queryInfo?.summary ?? error?.summary ?? tx("errors.try_again"),
  };
};

/**
 * Prevent injection by ensuring user input matches the format DB requires
 *  for identifiers within a query. Implemented as an alternative to
 *  Module, as Module doesn't work with static typing right now.
 *  https://faunadb.atlassian.net/browse/ENG-5003
 * @param identifier string
 * @returns Query
 */
export const FqlIdentifierRegex = /^[A-Za-z_][A-Za-z0-9_]*$/;

// Resources with v4 valid names but not v10 valid names still need to be
// validated by the FqlIdentifier function, since we frequently have lost the
// context of the resource alias by the time we call FqlIdentifier.
//
// This regex is the same as the one core uses:
// https://github.com/fauna/core/blob/046774ccea7fce11f4f5b58f944cb95c3e23a6d5/ext/model/src/main/scala/Parsing.scala#L13
const v4FqlIdentifierRegex = /^[;@+$\-_.!~%\w]+$/;

export const FqlIdentifier = (identifier: string): Query => {
  let templateStringsArray: any = [identifier];
  if (
    FqlIdentifierRegex.test(identifier) ||
    v4FqlIdentifierRegex.test(identifier)
  ) {
    return fql(templateStringsArray);
  } else {
    throw new Error(`Invalid identifier ${identifier}`);
  }
};

/**
 * This can be called with a couple of different formats, and given
 * the === I think this should be a safe comparison operation
 */
export const isLegacyFunctionBody = (body: any): boolean => {
  return body === "[function <lambda>]";
};
