import useSWR, { SWRConfiguration } from "swr";
import { useState } from "react";
import {
  cancelInvite,
  getInvites,
  getInviteByToken,
  resendInvite,
  sendInvite,
  type NewInvite,
} from "modules/api/auth-service/invites";

const key = "team_members_invites";

export const useInvites = (options?: SWRConfiguration) => {
  const {
    data: invites,
    error,
    mutate,
    isLoading,
  } = useSWR(key, getInvites, options);
  const [invitingLoading, setInvitingLoading] = useState(false);
  const [resendingLoading, setResendingLoading] = useState(false);
  const [cancelingLoading, setCancelingLoading] = useState(false);

  const send = {
    call: async (data: NewInvite) => {
      if (invitingLoading) return;

      try {
        setInvitingLoading(true);
        const newInvite = await sendInvite(data);
        mutate([...(invites ?? []), newInvite]);
        setInvitingLoading(false);
      } catch (error) {
        setInvitingLoading(false);
        throw error;
      }
    },
    loading: invitingLoading,
  };

  const resend = {
    call: async (inviteId: string) => {
      if (resendingLoading) return;
      if (!invites) return;

      try {
        setResendingLoading(true);
        await resendInvite(inviteId);
        mutate(invites.filter((invite) => invite.id !== inviteId));
        setResendingLoading(false);
      } catch (error) {
        setResendingLoading(false);
        throw error;
      }
    },
    loading: resendingLoading,
  };

  const cancel = {
    call: async (inviteId: string) => {
      if (cancelingLoading) return;
      if (!invites) return;

      try {
        setCancelingLoading(true);
        await cancelInvite(inviteId);
        mutate(invites.filter((invite) => invite.id !== inviteId));
        setCancelingLoading(false);
      } catch (error) {
        setCancelingLoading(false);
        throw error;
      }
    },
    loading: cancelingLoading,
  };

  return {
    data: invites,
    error,
    isLoading,
    resend,
    cancel,
    send,
  };
};

export const useInviteByToken = (
  inviteToken: string,
  options?: SWRConfiguration
) =>
  useSWR(`${key}/${inviteToken}`, () => getInviteByToken(inviteToken), options);
