// @ts-ignore - Allow Cypress tests to access their own environment variables when
// running application code from within the tests.
const cypressEnv = window.Cypress ? Cypress.env() : {};

const getEnvVar = (key: string) => {
  return (
    import.meta.env[key] ??
    import.meta.env[`VITE_${key}`] ??
    cypressEnv[key] ??
    cypressEnv[`CYPRESS_${key}`]
  );
};

/**
 * Consolidate all environment variables in one place to make it
 * easier to mock them in tests. More specifically jest does not
 * understand import.meta so all references must be mocked.
 */
export default {
  NODE_ENV: getEnvVar("NODE_ENV"),
  VITE_IS_LOCAL: getEnvVar("IS_LOCAL"),
  VITE_DB_URL: getEnvVar("DB_URL"),
  VITE_GRAPHQL_URL: getEnvVar("GRAPHQL_URL"),
  VITE_DEFAULT_REGION_GROUP: getEnvVar("DEFAULT_REGION_GROUP"),
  VITE_DD_APPLICATION_ID: getEnvVar("DD_APPLICATION_ID"),
  VITE_DD_CLIENT_TOKEN: getEnvVar("DD_CLIENT_TOKEN"),
  VITE_ENVIRONMENT: getEnvVar("ENVIRONMENT"),
  VITE_APP_VERSION: getEnvVar("APP_VERSION"),
  VITE_COOKIE_DOMAIN: getEnvVar("COOKIE_DOMAIN"),
  VITE_GTM_ID: getEnvVar("GTM_ID"),
  VITE_GA_ID: getEnvVar("GA_ID"),
  VITE_GOOGLE_RECAPTCHA_SITE_KEY: getEnvVar("GOOGLE_RECAPTCHA_SITE_KEY"),
  VITE_RECAPTCHA_ENABLED: getEnvVar("RECAPTCHA_ENABLED"),
  VITE_AUTH_URL: getEnvVar("AUTH_URL"),
  VITE_STRIPE_KEY: getEnvVar("STRIPE_KEY"),
  VITE_PAGE_SIZE: getEnvVar("PAGE_SIZE"),
  VITE_DISPLAY_PAGE_SIZE: getEnvVar("DISPLAY_PAGE_SIZE"),
  VITE_FORMSPREE_ENDPOINT_CUSTOM_RG: getEnvVar("FORMSPREE_ENDPOINT_CUSTOM_RG"),
  VITE_FORMSPREE_ENDPOINT_VPF_INFO: getEnvVar("FORMSPREE_ENDPOINT_VPF_INFO"),
  VITE_FORMSPREE_ENDPOINT_ENTERPRISE_INFO: getEnvVar(
    "FORMSPREE_ENDPOINT_ENTERPRISE_INFO"
  ),
  VITE_FRONTDOOR_URL: getEnvVar("FRONTDOOR_URL"),
  VITE_OLD_DASHBOARD_URL: getEnvVar("OLD_DASHBOARD_URL"),
  VITE_NEW_ROLES_PAGE: getEnvVar("NEW_ROLES_PAGE"),
};
