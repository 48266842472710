import type { Query } from "fauna";

// Identifier for resources that are being edited in the shell. Helps when multiple resources
//  have the same name, we allow a tab with the resource type as an tab name suffix
export type ResourceType =
  | "Collection"
  | "Document"
  | "Function"
  | "Database"
  | "DescendantDatabases"
  | "Role"
  | "Key"
  | "AccessProvider";

export const COLLECTION: ResourceType = "Collection";
export const DOCUMENT: ResourceType = "Document";
export const FUNCTION: ResourceType = "Function";
export const DATABASE: ResourceType = "Database";
export const DESCENDANT_DATABASES: ResourceType = "DescendantDatabases";
export const KEY: ResourceType = "Key";
export const ACCESS_PROVIDER: ResourceType = "AccessProvider";
export const ROLE: ResourceType = "Role";

export * from "./accessProvider";
export * from "./collection";
export * from "./database";
export * from "./document";
export * from "./function";
export * from "./key";
export * from "./role";

/**
 * Wrapper holding a {@link Query} and a string
 * to display the semantics of the query to the user
 * (i.e. the Query exported to a string).
 * @remarks
 * Core will natively support returning a display format
 * for interpoloated inputs via:
 *    https://faunadb.atlassian.net/browse/ENG-4819
 */
export interface QueryForDisplay {
  query: Query;
  display: string;
}
