import { BirdLogo, TextLogo, Layout } from "frontend-components";

import "./AuthForms.scss";

export const Logos = ({
  alwaysDisplay = false,
}: {
  alwaysDisplay: boolean;
}) => {
  // On some auth pages we show the logos elsewhere on the page and don't
  //  want to display it again above the login form. According to figma mocks.
  const className = `logos-wrapper ${alwaysDisplay ? "always-display" : ""}`;
  return (
    <Layout flow="left-right" gap="md" className={className} padding="sm">
      <div className="bird-logo-wrapper">
        <BirdLogo />
      </div>
      <div className="text-logo-wrapper">
        <TextLogo />
      </div>
    </Layout>
  );
};
