import { FC } from "react";
import FullScreenOverlayLayout from "../../layouts/FullScreenOverlayLayout";

import { PropTypes } from "./Loading.types";

import { Layout, Spinner } from "frontend-components";

const Loading: FC<PropTypes> = ({ fullscreen = true }) => {
  const spin = (
    <Layout
      width="full"
      height="full"
      alignItems="center"
      justifyContent="center"
      data-testid="loading"
    >
      <Spinner />
    </Layout>
  );

  if (fullscreen)
    return <FullScreenOverlayLayout>{spin}</FullScreenOverlayLayout>;

  return spin;
};

export default Loading;
