import { getSession } from "../../../modules/session";

export type DatabaseKeyEntry = {
  /** The secret for the key. */
  databaseKey: string;

  /** When the key expires in a unix timestamp. */
  expiresAt: number;
};

/**
 * A map of database keys to their entries. This interface is a subset of the
 * Map interface. The simplest implementation is just extending Map by adding
 * a getByDatabaseKey method.
 */
export type DatabaseKeyStoreMap = Pick<
  Map<string, DatabaseKeyEntry>,
  "get" | "has" | "delete" | "size" | "clear"
> & {
  /** Sets the key to the given value and returns the modified map. */
  set: (key: string, value: DatabaseKeyEntry) => DatabaseKeyStoreMap;

  /** Returns the key for the given database key. */
  getByDatabaseKey: (databaseKey: string) => string | undefined;
};

/**
 * A simple implementation of DatabaseKeyStoreMap that extends Map by adding
 * the getByDatabaseKey method.
 *
 * This class should be used when storage is not available.
 */
export class MapStore
  extends Map<string, DatabaseKeyEntry>
  implements DatabaseKeyStoreMap
{
  // Returns a reverse lookup of the key by the database key
  getByDatabaseKey(databaseKey: string): string | undefined {
    for (const [key, keyEntry] of this.entries()) {
      if (keyEntry.databaseKey === databaseKey) {
        return key;
      }
    }
  }
}

export type StorageMapOptions = {
  /** The prefix to namespace the keys. */
  prefix: string | (() => string);

  /** The storage to use (i.e., localStorage or sessionStorage). */
  storage: Storage;
};

/**
 * StorageMapStore is a DatabaseKeyStoreMap that stores keys in a Storage
 * implementation. The prefix is used to namespace the keys. All keys for a
 * given prefix are stored in a single blob.
 */
export class StorageMapStore implements DatabaseKeyStoreMap {
  private prefixFn: () => string;
  private storage: Storage;

  constructor({ prefix, storage }: StorageMapOptions) {
    this.prefixFn = typeof prefix != "function" ? () => prefix : prefix;
    this.storage = storage;
  }

  private get prefix(): string {
    return this.prefixFn();
  }

  /** Returns the current map of keys */
  private getStore(): Record<string, DatabaseKeyEntry> {
    return JSON.parse(this.storage.getItem(this.prefix) ?? "{}");
  }

  /** Sets the current map of keys */
  private setStore(store: Record<string, DatabaseKeyEntry>): void {
    this.storage.setItem(this.prefix, JSON.stringify(store));
  }

  /** Sets the key to the given value and returns the modified map. */
  set(key: string, value: DatabaseKeyEntry): this {
    const store = this.getStore();
    store[key] = value;

    this.setStore(store);

    return this;
  }

  /** Returns the key for the given key. */
  get(key: string): DatabaseKeyEntry | undefined {
    const store = this.getStore();
    return store[key];
  }

  /** Returns a reverse lookup of the key by the database key */
  getByDatabaseKey(databaseKey: string): string | undefined {
    for (const [key, keyEntry] of Object.entries(this.getStore())) {
      if (keyEntry.databaseKey === databaseKey) {
        return key;
      }
    }
  }

  /** Returns true if the key exists. */
  has(key: string): boolean {
    return this.getStore()[key] !== undefined;
  }

  /** Deletes the key and returns true if the key existed. */
  delete(key: string): boolean {
    const store = this.getStore();
    if (store[key] !== undefined) {
      delete store[key];
      this.setStore(store);
      return true;
    }

    return false;
  }

  /** Clears the current map of keys. */
  clear(): void {
    this.storage.removeItem(this.prefix);
  }

  /** Returns the number of keys in the map. */
  get size(): number {
    return Object.keys(this.getStore()).length;
  }
}

/**
 * Checks if the given storage is available. This is a straight pull from how
 * modernizr does it.
 */
export const isStorageAvailable = (storage: Storage) => {
  try {
    storage.setItem("database-keys-test", "test");
    storage.removeItem("database-keys-test");
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Returns a prefix based on the current session's user id.
 */
const getSessionPrefix = () => {
  const { user } = getSession() ?? {};
  return `${user?.id ?? ""}/keys`;
};

/**
 * Creates a new StorageMapStore prefixed by the logged in user's id
 */
export const createStorageMapFromSession = (): DatabaseKeyStoreMap => {
  if (!isStorageAvailable(sessionStorage)) {
    throw new Error("session storage is not available");
  }

  return new StorageMapStore({
    prefix: getSessionPrefix,
    storage: sessionStorage,
  });
};
