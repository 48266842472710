import { isBuiltInRole } from "./resources/role";

export type AuthScope = {
  role?: string;
  authDoc?: {
    collection: string;
    id: string;
  };
};

export type Scope = AuthScope & { dbPath?: string };

/**
 * Gets the secret needed for a given scope.
 * @param baseSecret the base secret
 * @param options
 * @param options.dbPath a database path to scope the secret to. Pass as "" to
 *   use the scope of the base secret
 * @param options.authDoc optional auth doc to scope the secret to a document
 * @param options.authDoc.collection the collection of the document to scope the secret to
 * @param options.authDoc.id the id of the document to scope the secret to
 * @param options.role the optional role to scope the secret with
 * @return the scoped secret
 */
export function getScopedSecret(
  baseSecret: string,
  { dbPath = "", authDoc, role = "admin" }: Scope
) {
  const baseKey = `${baseSecret}:${dbPath}`;
  if (authDoc) return `${baseKey}:@doc/${authDoc.collection}/${authDoc.id}`;
  return `${baseKey}:${isBuiltInRole(role) ? role : `@role/${role}`}`;
}
