import env from "src/env";
import getHeaders from "./headers";
import { ViewControls } from "modules/session";
import { RegionInfo } from "modules/region/config";
import { Limits } from "modules/api/auth-service/permissions";
import { fetchWithCredentials } from "modules/api/utils/fetch";
import { commonResponseHandler } from "modules/api/auth-service/common";

export const AUTHZ_API_URL = `${env.VITE_AUTH_URL}/auth/identity`;

export type FeatureFlags = Record<string, boolean>;

export type UserIdentity = {
  view_controls: ViewControls;
  role: string;
  plan: string;
  planSynced: boolean;
  plan_display_name: string;
  plan_monthly_price: number;
  is_plan_deprecated: boolean;
  limits: Limits;
  id: string;
  feature_flags: FeatureFlags;
  userRegionGroups: Record<string, RegionInfo>;
  eligible_for_trial: boolean;
  otp_enabled: boolean;
  pending_email: string;
};

/**
 * Fetches the user's identity from the auth service.
 *
 * @returns The user's identity.
 * @throws A FetchError if the request fails.
 */
export const getUserIdentity = async (): Promise<UserIdentity> => {
  const response = await fetchWithCredentials({
    url: AUTHZ_API_URL,
    options: { method: "GET" },
    oldAuthHeaders: getHeaders(),
  });

  return commonResponseHandler<UserIdentity>({
    response,
    defaultErrorMessage: "Failed to fetch user identity",
  });
};
