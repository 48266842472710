import env from "src/env";
import { Limits, Plan } from "modules/api/auth-service/permissions";
import {
  SetupIntent,
  PaymentMethod,
  ExpressCheckoutAddress,
} from "@stripe/stripe-js";
import getHeaders from "./headers";
import type { SessionRegion } from "modules/session";
import type { RegionInfo } from "modules/region/config";
import { commonResponseHandler } from "modules/api/auth-service/common";
import { fetchWithCredentials } from "modules/api/utils/fetch";

export const FREE_USAGE_PLANS: Array<Plan | undefined> = [Plan.free];
export const TEAM_USAGE_PLANS: Array<Plan | undefined> = [
  Plan.team,
  Plan.business,
];
export const UPGRADEABLE_USAGE_PLANS: Array<Plan | undefined> = [
  Plan.free,
  Plan.individual,
  Plan.team,
  Plan.business,
];

export const INVOICE_NEEDS_ACTION_STATUSES = new Set([
  "Failed",
  "Retrying",
  "Collections",
]);
export const INVOICE_PAID_STATUSES = new Set(["Paid"]);

export type BillingInfo = {
  plans: {
    usage: Plan;
  };
  payment_method_id: string;
  company_name: string;
  billing_email: string;
  phone_number?: string;
  free_usage_amount: number;
  default_payment_method_id: string;
};

export type UpgradeResponse = BillingInfo & {
  regionGroups: Record<string, SessionRegion>;
  userRegionGroups: Record<string, RegionInfo>;
  limits: Limits;
};

export type BillingPreview = {
  start_period: string;
  end_period: string;
  total_amount: number;
  free_usage_amount: number;
  line_items: Array<{ description: string; amount: number }>;
  metric_amount: any;
};

export type StripeCustomer = {
  name: string;
  email: string;
  phone: string;
  default_payment_method: string;
  metadata: Record<string, string>;
};

export type StripePaymentFormAddress = {
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postal_code: string;
  country: string;
};

export type CustomerTaxMetadata = {
  Address_Line_1: string;
  Address_Line_2: string;
  Address_City: string;
  Address_State: string;
  Address_PostalCode: string;
  Address_Country: string;
};

export type PaymentMethodSuccessParams = {
  setupIntent?: SetupIntent;
  addressData?: StripePaymentFormAddress;
};

export type Invoice = {
  date: string;
  amount: number;
  id: string;
  payment_method: string;
  status: string;
  url: string;
};

type CreateSetupIntentParams = {
  captcha_token?: string;
};

type UpdatePlanParams = {
  plan: Plan;
};

export const BILLING_INFO_API_URL = `${env.VITE_AUTH_URL}/account/billing`;
export const PAYMENT_SOURCES_API_URL = `${env.VITE_AUTH_URL}/account/billing/sources`;
export const PAYMENT_METHODS_API_URL = `${env.VITE_AUTH_URL}/account/billing/payment_methods`;
export const SETUP_INTENT_API_URL = `${env.VITE_AUTH_URL}/account/billing/setup_intent`;
export const STRIPE_CUSTOMER_API_URL = `${env.VITE_AUTH_URL}/account/billing/customer`;
export const INVOICES_API_URL = `${env.VITE_AUTH_URL}/account/billing/invoices`;
export const UPGRADE_API_URL = `${env.VITE_AUTH_URL}/account/billing/upgrade`;

/**
 * Gets the billing info for the current user.
 *
 * @returns The billing info.
 * @throws A FetchError if the request fails.
 */
export const getBillingInfo = async (): Promise<BillingInfo> => {
  const response = await fetchWithCredentials({
    url: BILLING_INFO_API_URL,
    options: { method: "GET" },
    oldAuthHeaders: getHeaders(),
  });

  return commonResponseHandler<BillingInfo>({
    response,
    defaultErrorMessage: "Failed to fetch billing info",
  });
};

/**
 * Gets the payment methods for the current user. A user may have
 * 0 or more payment methods.
 *
 * @returns The list of payment methods.
 * @throws A FetchError if the request fails.
 */
export const getPaymentMethods = async (): Promise<Array<PaymentMethod>> => {
  const response = await fetchWithCredentials({
    url: PAYMENT_METHODS_API_URL,
    options: { method: "GET" },
    oldAuthHeaders: getHeaders(),
  });

  return commonResponseHandler<Array<PaymentMethod>>({
    response,
    defaultErrorMessage: "Failed to fetch payment methods",
  });
};

/**
 * Creates a setup intent for the current user. A setup intent is the
 * first step in creating a new payment method for the user.
 *
 * @param params The setup intent params.
 * @returns The setup intent.
 * @throws A FetchError if the request fails.
 */
export const createSetupIntent = async (
  params: CreateSetupIntentParams
): Promise<SetupIntent> => {
  const response = await fetchWithCredentials({
    url: SETUP_INTENT_API_URL,
    options: {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
    },
    oldAuthHeaders: getHeaders(),
  });

  return commonResponseHandler<SetupIntent>({
    response,
    defaultErrorMessage: "Failed to create setup intent",
  });
};

/**
 * Updates the current user's customer record in stripe.
 *
 * @param customer The customerdata to update.
 * @returns The updated stripe record.
 * @throws A FetchError if the request fails.
 */
export const updateStripeCustomer = async (
  customer: Partial<StripeCustomer>
): Promise<StripeCustomer> => {
  const response = await fetchWithCredentials({
    url: STRIPE_CUSTOMER_API_URL,
    options: {
      method: "PUT",
      body: JSON.stringify(customer),
      headers: { "Content-Type": "application/json" },
    },
    oldAuthHeaders: getHeaders(),
  });

  return commonResponseHandler<StripeCustomer>({
    response,
    defaultErrorMessage: "Failed to update customer info",
  });
};

/**
 * Deletes a payment method associated with the current user. If a user
 * is on a paid plan, they may not delete their only payment method.
 *
 * @param paymentMethodId The ID of the payment method to delete.
 * @throws A FetchError if the request fails.
 */
export const deletePaymentMethod = async (
  paymentMethodId: string
): Promise<void> => {
  const response = await fetchWithCredentials({
    url: `${PAYMENT_METHODS_API_URL}/${paymentMethodId}`,
    options: { method: "DELETE" },
    oldAuthHeaders: getHeaders(),
  });

  return commonResponseHandler({
    response,
    expectResponseData: false,
    defaultErrorMessage: "Failed to delete payment method",
  });
};

/**
 * Lists invoices for the current user.
 *
 * @returns The list of invoices.
 * @throws A FetchError if the request fails.
 */
export const getInvoices = async (): Promise<Array<Invoice>> => {
  const response = await fetchWithCredentials({
    url: INVOICES_API_URL,
    options: { method: "GET" },
    oldAuthHeaders: getHeaders(),
  });

  return commonResponseHandler<Array<Invoice>>({
    response,
    defaultErrorMessage: "Failed to fetch invoices",
  });
};

/**
 * Updates the current user's plan.
 *
 * @param params The plan params.
 * @returns The updated plan.
 * @throws A FetchError if the request fails.
 */
export const updatePlan = async ({
  plan,
}: UpdatePlanParams): Promise<UpgradeResponse> => {
  const response = await fetchWithCredentials({
    url: UPGRADE_API_URL,
    options: {
      method: "POST",
      body: JSON.stringify({ plans: { usage: plan } }),
      headers: { "Content-Type": "application/json" },
    },
    oldAuthHeaders: getHeaders(),
  });

  return commonResponseHandler<UpgradeResponse>({
    response,
    defaultErrorMessage: "Failed to update plan",
  });
};

export const customerTaxMetadataFromAddress = (
  addressData: ExpressCheckoutAddress
): CustomerTaxMetadata => {
  return {
    Address_Line_1: addressData.line1,
    Address_Line_2: addressData.line2 || "",
    Address_City: addressData.city,
    Address_State: addressData.state,
    Address_PostalCode: addressData.postal_code,
    Address_Country: addressData.country,
  };
};
