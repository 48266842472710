export enum Role {
  owner = "owner",
  admin = "admin",
  billing = "billing",
  developer = "developer",
}

export enum Plan {
  free = "free",
  individual = "individual",
  team = "team",
  business = "business",
}

export enum PermissionLevel {
  read = "read",
  update = "update",
  delete = "delete",
  list = "list",
  create = "create",
  // while "write" doesn't come back from the API, we allow components
  //  to request it, and we'll return true if the user has all of
  //  "create", "update", and "delete" permissions.
  write = "write",
}

export enum PermissionDomain {
  "*" = "*",
  ACCOUNT = "account",
  ACCOUNT_KEYS = "account-keys",
  BACKUP_CONFIG = "backup-configurations",
  // TODO: this is set to backup-configurations right now
  //  because we don't have any separation between copy/restore/snapshots
  //  when deciding to render B&R UI. As we lock down these UI features separately,
  //  we should eventually get rid of this BACKUP_RESTORE domain.
  BACKUP_RESTORE = "backup-configurations",
  BILLING = "billing",
  BILLING_FIRST_PARTY = "billing-first-party",
  COPIES = "copies",
  INTEGRATIONS = "integrations",
  QUERYLOG = "logs/query",
  RESTORES = "restores",
  SNAPSHOTS = "snapshots",
  TEAM_MANAGEMENT = "team-management",
  UPGRADES = "upgrades",
  WHOAMI = "whoami",
  V4 = "v4",
}

export type Limit = { soft?: number; hard?: number };
export type Limits = {
  read_ops?: Limit;
  write_ops?: Limit;
  compute_ops?: Limit;
  db_count?: Limit;
  /** history days limit */
  temporality?: Limit;
  /** total team members including the owner */
  team_members?: Limit;
};

export const PlanFeatureLookup: Record<string, Array<PermissionDomain>> = {
  free: [],
  individual: [],
  team: [PermissionDomain.BACKUP_RESTORE, PermissionDomain.QUERYLOG],
  business: [PermissionDomain.BACKUP_RESTORE, PermissionDomain.QUERYLOG],
  "Free-V2": [],
  "Pay-As-You-Go": [],
  Startup: [PermissionDomain.BACKUP_RESTORE],
  Trial: [
    PermissionDomain.BACKUP_RESTORE,
    PermissionDomain.QUERYLOG,
    PermissionDomain.INTEGRATIONS,
  ],
  Pro: [
    PermissionDomain.BACKUP_RESTORE,
    PermissionDomain.QUERYLOG,
    PermissionDomain.INTEGRATIONS,
  ],
  "Enterprise-Low": [
    PermissionDomain.BACKUP_RESTORE,
    PermissionDomain.QUERYLOG,
    PermissionDomain.INTEGRATIONS,
  ],
  "Enterprise-Low-1k": [
    PermissionDomain.BACKUP_RESTORE,
    PermissionDomain.QUERYLOG,
    PermissionDomain.INTEGRATIONS,
  ],
  "Enterprise-Low-1.5k": [
    PermissionDomain.BACKUP_RESTORE,
    PermissionDomain.QUERYLOG,
    PermissionDomain.INTEGRATIONS,
  ],
  "Enterprise-Medium": [
    PermissionDomain.BACKUP_RESTORE,
    PermissionDomain.QUERYLOG,
    PermissionDomain.INTEGRATIONS,
  ],
  "Enterprise-High": [
    PermissionDomain.BACKUP_RESTORE,
    PermissionDomain.QUERYLOG,
    PermissionDomain.INTEGRATIONS,
  ],
};
