import {
  fql,
  QueryValueObject,
  Query,
  QueryValue,
  DocumentT,
  QuerySuccess,
} from "fauna";
import { mutate } from "swr";
import { DEFAULT_PAGE_SIZE, FaunaAPI } from ".";
import { PaginateOptions } from ".";
import { QueryForDisplay, ResourceType } from "./resources";
import { MAX_COUNT } from "constants/index";

/**
 * The basic set of fields you get back when you `get` any document.
 * @template DataT the type of the `data` field.
 */
export type BaseDoc<DataT extends QueryValueObject> = DocumentT<DataT>;

/**
 * The basic set of parameters you can provide when you `create` or `update` any
 * document.
 * @template DataT type of the `data` field
 */
export type BaseParams<DataT extends QueryValue> = DataT;

/**
 * A single accessor for a specific resource.
 */
export type ResourceAccessor<ParamT, DataT extends QueryValue> = (
  queryArgs: ParamT,
  resourceId?: string
) => {
  execute: (apiOverride?: FaunaAPI) => Promise<QuerySuccess<DataT>>;
  builtQuery: QueryForDisplay;
};

/**
 * Saves a literal query value.
 */
export type ShellSaveParams = {
  name: string;
  query: string;
};

/**
 * Set of custom accessors for a specific resource. Every resource
 *  must provide a save implementation for when it's being modified in the
 *  shell.
 */
export interface ResourceAccessors {
  [key: string]: ResourceAccessor<any, any>;
  shellSave: ResourceAccessor<ShellSaveParams, string>;
}

/**
 * @return Query for counting the number of items.
 */
function count(collection: Query) {
  return fql`${collection}
          .all()
          .take(${MAX_COUNT})
          .count()`;
}

/**
 * @return Query for counting the number of items.
 */
function countWhere(collection: Query, where: Query) {
  return fql`${collection}
          .all()
          .take(${MAX_COUNT})
          .${where}
          .count()`;
}

/**
 * A class of entities represented by a collection of documents in a Fauna database.
 * This base class provides basic CRUD operations.
 * @template DataT type of the `data` field in the document.
 * @template DocT type of the query response document.
 * @template ParamsT type of the parameters passed to Fauna `create` and `update`.
 * @template IdT type of the identifier that uniquely identifies the entity.
 */
export abstract class Resource<AccessorT extends ResourceAccessors> {
  readonly api: FaunaAPI;

  /**
   * The Fauna collection that houses the documents that represent this
   * `Resource`'s entities.
   */
  // collection will be Function, Collection, Database, Key, or Role
  readonly collection: Query;

  /**
   * String representation of the type of resource we are dealing with.
   */
  readonly resourceType: ResourceType;

  /**
   * String representation of the type of resource we are dealing with when
   * listing resources of a given type. Defaults to the same value as `resourceType`.
   * But can be a different value - useful in cases such as databases where a listing
   * of databases corresponds to the `DescendantDatabases` resource rather than
   * the `Database` resource which is used for a specific top level database.
   */
  readonly listResourceType: ResourceType;

  /**
   * @param api the FaunaAPI instance to connect to.
   * @param collection ref of the collection that contains the resource documents.
   * @param accessors accessor funnctions for the resource.
   * @param resourceType the resource type for this resource.
   * @param listResourceType the resource type for lists of this resource.
   */
  constructor(
    api: FaunaAPI,
    collection: Query,
    accessors: AccessorT,
    resourceType: ResourceType,
    listResourceType?: ResourceType
  ) {
    this.api = api;
    this.collection = collection;
    this.accessors = accessors;
    this.resourceType = resourceType;
    this.listResourceType = listResourceType || resourceType;
  }

  // The path to the `/schema/1/items/<path>` endpoint.
  path = "";

  // Similar to the retrieveQ for fql queries, this is helpful when
  //  we need to mutate the SWR cache key after a write operation to
  //  a schema item.
  schemaItemUrl = schemaItemUrlBuilder(this.path);

  // If the resourceId can be mutated (e.g. Collection name is mutable, but Document id is not)
  //  then use this boolean for branching logic to conditionally pull out the
  //  resourceId from the decorated response, and update it in the tab attributes
  isResourceIdMutable = false;

  /**
   * Schema resources are not v10 compatible when they contain v4 lambdas in their
   * definitions.This means we can't open a schema editor for this entity
   * and need to direct the user to the v4 dashboard.
   *
   * todo: fix the 'any' parameter type.  This is currently here because it looks
   * like our schema methods off our useAPI() are returning any, so we don't have
   * the type when calling this.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  notV10Compatible = (resource: any): boolean => {
    return false;
  };

  /**
   * If a resource has a name that is not a valid FQL identifier and does not have an alias,
   * it needs an alias to be v10 compatible.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  needsAlias = (resource: any): boolean => {
    return false;
  };

  // If this resource is a document resource, this is the collection name of that document.
  collectionName: string | undefined = undefined;

  // When getting data back in decorated format, we may need to pull out the
  //  id in case it was updated. For parsing purposes, define what attribute should
  //  be pulled out of the decorated string response. e.g. Document uses "id" while
  //  collection uses "name"
  resourceIdDecoratedKey = "name";

  // Regular Expressions that are used to remove reserved words from the decorated api response
  //  when fetching a resource. This allows us to display only those attributes that a user
  //  is allowed to update
  reservedWordsRegexes: Array<RegExp> = [];

  // Define the font awesome icon to use in the Shell tab for this type of resource
  shellTabIconClass = "fa-file";

  /**
   * @param options specifies which page to get.
   * @return a query for getting a page of refs in the collection and an estimate
   *   of the count of results
   */
  refsQ = (options: PaginateOptions): Query => {
    if (options.dropTake !== undefined) {
      return fql`
        let results = (${this.collection}
          .all()
          .drop(${options.dropTake.drop})
          .take(${options.dropTake.take + 1}) { ${fql([
        this.resourceIdDecoratedKey,
      ])} }
        ).paginate(${options.dropTake.take})
        let count = ${count(this.collection)}
        { results: results, count: count }
      `;
    }
    return fql`(${this.collection}.all() { ${fql([
      this.resourceIdDecoratedKey,
    ])} }).paginate(${options?.size || DEFAULT_PAGE_SIZE})`;
  };

  /**
   * @param options specifies which page to get.
   * @return a query for getting a page of documents in the collection.
   */
  docsQ = (options: PaginateOptions): Query => {
    if (options.dropTake !== undefined) {
      return fql`
        let results = (${this.collection}
          .all()
          .drop(${options.dropTake.drop})
          .take(${options.dropTake.take + 1})
        ).paginate(${options.dropTake.take})
        let count = ${count(this.collection)}
       { results: results, count: count }
      `;
    }
    return fql`${this.collection}.all().paginate(${
      options?.size || DEFAULT_PAGE_SIZE
    })`;
  };

  /**
   * @param options specifies which page to get.
   * @param searchValue the ID to search for.
   * @return a query for getting a single document by ID formatted as a page.
   */
  searchQ = (options: PaginateOptions, searchValue: string): Query => {
    if (!searchValue) return this.docsQ(options); // If no search value is provided, return all docs
    if (options.dropTake !== undefined) {
      return fql`
        let document = ${this.collection}.byId(${searchValue})
        let count = ${count(this.collection)}
        let filteredCount = if (document.exists()) 1 else 0
        let results = if (document.exists()) Set.single(document) else [].toSet()
        { results: results, count: count, filteredCount: filteredCount }
      `;
    }
    return fql`Set.single(${this.collection}.byId(${searchValue}))`;
  };

  /**
   * Returns a query that retrieves a single document in the resource collection
   * by its ID.
   * @param id the ID to get.
   * @returns a query expression
   */
  retrieveQ = (id: string) => {
    return fql`${this.collection}.byName(${id})`;
  };

  /**
   * The query format for operating on an FQL-X resource differs between resources. It
   * wouldn't be very useful to have shared queries here for CRUD methods, the way we have
   * them for list (refsQ, docsQ). When instantiating a new Resource class, the specific
   * resources should build a set of accessors. Because these are defined first, then passed
   * into the resource constructor, we have their full type definitions when we use the resource
   * in a hook like `useResource`.
   */
  accessors;

  /**
   * Strips reserved FQL X keywords from a decorated version of a resource.
   * See https://faunadb.atlassian.net/browse/ENG-4837 for a better solution
   * @param decoratedFormat the decorated format of the resource
   * @return the decorated format of the resource with all the keywords removed.
   */
  prepareDecoratedResponse = (decoratedFormat: string) => {
    return this.reservedWordsRegexes.reduce(
      (acc, re) => acc.replace(re, "\n"),
      decoratedFormat
    );
  };

  /**
   * The db's decorated response object is a string representation of the resource
   * document. Until we have a way to turn that into parse-able json or an object
   * that lets us cleanly pull out attribute values, we need a way to get the result
   * of an fql query. A user may set a new name for their collection: "my" + "collection".
   * We have to wait for the response to get the computed value and pull it out of the
   * string.
   * @param response
   * @returns
   */
  // TODO: this might not be used anymore since doc editor is the only one using this and
  //  docs cannot be renamed
  parseResourceIdFromDecoratedResponse = (decoratedFormat: string) => {
    const match = decoratedFormat.match(
      new RegExp(`^([\\s\\S]+?${this.resourceIdDecoratedKey}: ")(.*\\w)`)
    );
    if (!match || match.length < 3) {
      return "";
    }
    return match[2];
  };
}

export abstract class NamedResource<
  AccessorT extends ResourceAccessors
> extends Resource<AccessorT> {
  /**
   * {@inheritDoc Resource.refsQ}
   */
  refsQ = (options: PaginateOptions): Query => {
    if (options.dropTake !== undefined) {
      return fql`
        let results = (${this.collection}
          .all()
          .take(${MAX_COUNT})
          .order(.name.toLowerCase())
          .drop(${options.dropTake.drop})
          .take(${options.dropTake.take + 1}) { ${fql([
        this.resourceIdDecoratedKey,
      ])} }
        ).paginate(${options.dropTake.take})
        let count = ${count(this.collection)}
        { results: results, count: count }
      `;
    }
    const pageSize = options?.size || DEFAULT_PAGE_SIZE;
    return fql`(${this.collection}.all().order(.name.toLowerCase()) { ${fql([
      this.resourceIdDecoratedKey,
    ])} }).paginate(${pageSize})`;
  };

  /**
   * {@inheritDoc Resource.docsQ}
   */
  docsQ = (options: PaginateOptions): Query => {
    if (options.dropTake !== undefined) {
      return fql`
        let results = (${this.collection}
          .all()
          .take(${MAX_COUNT})
          .order(.name.toLowerCase())
          .drop(${options.dropTake.drop})
          .take(${options.dropTake.take + 1})
        ).paginate(${options.dropTake.take})
        let count = ${count(this.collection)}
        { results: results, count: count }
      `;
    }
    const pageSize = options?.size || DEFAULT_PAGE_SIZE;
    return fql`${this.collection}.all().order(.name.toLowerCase()).paginate(${pageSize})`;
  };

  /**
   * @param options specifies which page to get.
   * @param searchValue the name of the document to search for.
   * @return a query for getting a page of documents whose name starts with the given search value.
   */
  searchQ = (options: PaginateOptions, searchValue: string): Query => {
    const whereClause = fql`where(.name.toLowerCase().startsWith(${searchValue.toLowerCase()}))`;
    if (options.dropTake !== undefined) {
      return fql`
        let results = (${this.collection}
          .all()
          .take(${MAX_COUNT})
          .order(.name.toLowerCase())
          .${whereClause}
          .drop(${options.dropTake.drop})
          .take(${options.dropTake.take + 1})
        ).paginate(${options.dropTake.take})
        let count = ${count(this.collection)}
        let filteredCount = ${countWhere(this.collection, whereClause)}
        { results: results, count: count, filteredCount: filteredCount }
      `;
    }
    return fql`${
      this.collection
    }.${whereClause}.order(.name.toLowerCase()).paginate(${
      options?.size || DEFAULT_PAGE_SIZE
    })`;
  };
}

// Helper method to build the schema item url for a given resource.
export const schemaItemUrlBuilder = (path: string) => {
  return (name: string) => `schema/1/items/${path}/${name}`;
};

/**
 * Each resource type has various FQL-x queries defined which are used to CRUD that
 * specific resource. The query formats and argument types vary between resources so we need
 * a generic way to build out these Typed CRUD methods for each resource.
 * A resource accessor is a way to wrap up one of these queries into an object that components
 *  can use to:
 *  1. build a query
 *  2. execute it
 *  3. Mutate an SWR key if necessary (keeping Reads up to date after writes)
 * This way we can do:
 * `const {create, useAll, methdoSpecificToFunctions} = useFauna().useCurrentDatabase().useFunctions()`
 * And the component has access to a type-protected, custom method. This makes for cleaner code
 * when using resources anywhere on the site, and keeps the usage of these custom query strings to one place.
 * @param queryBuilderFunction Function to build the query string
 * @param api instance of FaunaAPI to call
 * @returns { execute: call FaunaAPI with the built query, displayQuery: string representation of built query}
 */
export function buildAccessor<ParamT, DataT extends QueryValue>(
  queryBuilderFunction: (args: ParamT) => QueryForDisplay,
  api: FaunaAPI,
  retrieveQuery?: (resourceId: string) => Query
): ResourceAccessor<ParamT, DataT> {
  return (queryArgs: ParamT, resourceId?: string) => {
    const builtQuery = queryBuilderFunction(queryArgs);
    return {
      execute: (apiOverride?: FaunaAPI) => {
        return (apiOverride || api)
          .query<DataT>(builtQuery.query)
          .then((result) => {
            resourceId !== undefined &&
              retrieveQuery &&
              mutate(api.cacheKey(retrieveQuery(resourceId)));
            return result;
          });
      },
      builtQuery,
    };
  };
}
