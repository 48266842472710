import * as React from "react";
import { useNavigate } from "react-router-dom";
import { tx } from "modules/language/translate";
import FullScreenOverlayLayout from "../../layouts/FullScreenOverlayLayout";

import {
  Card,
  Heading,
  Text,
  Button,
  Paragraph,
  Layout,
} from "frontend-components";

type PropTypes = {
  title: string;
  message: string | React.ReactElement;
  suggestion?: string;
  failureReasons?: Array<string>;
  buttonText?: string;
  onButtonClick?: () => void;
};

const ErrorPage: React.FC<PropTypes> = ({
  title,
  message,
  suggestion,
  failureReasons,
  buttonText,
  onButtonClick,
}) => {
  const navigate = useNavigate();

  const reasons = (failureReasons || []).map((reason, index) => {
    return (
      <li key={"StatusReason-" + index}>
        <Text size="lg" wrapper="span">
          {reason}
        </Text>
      </li>
    );
  });

  return (
    <FullScreenOverlayLayout>
      <Layout
        width="full"
        height="full"
        alignItems="center"
        justifyContent="center"
      >
        <Card shadow="sm" padding="lg">
          <Heading size="h2">{title}</Heading>
          <Paragraph margin="lg">
            {typeof message !== "string" && message}
            {typeof message === "string" && <Text>{message}</Text>}
            {failureReasons && (
              <React.Fragment>
                <Text>{"This typically results from the following:"}</Text>
                {reasons}
              </React.Fragment>
            )}
            {suggestion && <Text>{suggestion}</Text>}
          </Paragraph>
          <Button size="lg" onClick={onButtonClick || (() => navigate("/"))}>
            {buttonText || tx("actions.go_back")}
          </Button>
        </Card>
      </Layout>
    </FullScreenOverlayLayout>
  );
};

export default ErrorPage;
