import { DateStub, QueryValue, TimeStub } from "fauna";

/**
 * Displays a value in the decorated format. The second parameter is an optional
 * indent level to start at, which defaults to 0.
 */
export function display(v: QueryValue, indent?: number): string {
  const d = new Displayer();
  if (indent) {
    d._indent = indent;
  }
  d.display(v);
  return d.buf;
}

class Displayer {
  buf = "";
  needs_indent = false;
  _indent = 0;

  display(v: QueryValue) {
    if (v === null) {
      this.write("null");
    } else if (typeof v === "string") {
      this.displayStr(v);
    } else if (typeof v === "number") {
      this.write(v.toString());
    } else if (typeof v === "object") {
      if (v instanceof Array) {
        this.writeln("[");
        this.indent();
        for (let i = 0; i < v.length; i++) {
          const value = v[i];
          this.display(value);
          if (i < v.length - 1) {
            this.write(",");
          }
          this.writeln("");
          i += 1;
        }
        this.deindent();
        this.write("]");
      } else if (v instanceof TimeStub) {
        this.write('Time("');
        this.write(v.isoString);
        this.write('")');
      } else if (v instanceof DateStub) {
        this.write('Date("');
        this.write(v.dateString);
        this.write('")');
      } else {
        this.writeln("{");
        this.indent();
        const entries = Object.entries(v);
        for (let i = 0; i < entries.length; i++) {
          const [key, value] = entries[i];
          this.displayIdent(key);
          this.write(": ");
          this.display(value);
          if (i < entries.length - 1) {
            this.write(",");
          }
          this.writeln("");
        }
        this.deindent();
        this.write("}");
      }
    }
  }

  displayIdent(v: string) {
    if (v.match(/^[A-Za-z_][A-Za-z0-9_]*$/) != null) {
      this.write(v);
    } else {
      this.displayStr(v);
    }
  }

  displayStr(v: string) {
    if (v.includes("\n")) {
      // build this:
      //   body: <<-END
      //     (x) => {
      //       log("y")
      //       log("hi")
      //       x + 3
      //     }
      //   END
      this.writeln("<<-END");
      this.indent();
      for (const line of v.split("\n")) {
        this.writeln(line);
      }
      this.deindent();
      this.write("END");
    } else {
      // Copied from core ext/fql/shared/src/main/scala/parser/Strings.scala
      const unescaped: { [key: string]: string } = {
        "\\": "\\",
        "\u0000": "0",
        "'": "'",
        '"': '"',
        "`": "`",
        "\n": "n",
        "\r": "r",
        "\u000b": "v",
        "\t": "t",
        "\b": "b",
        "\f": "f",
        "#": "#",
      };
      // This handles indents
      this.write('"');
      // This won't write any newlines, so we don't need to worry about indents.
      for (const c of v) {
        const unescapedChar = unescaped[c];
        if (unescapedChar) {
          this.buf += "\\";
          this.buf += unescapedChar;
        } else {
          this.buf += c;
        }
      }
      this.write('"');
    }
  }

  write(v: string) {
    if (this.needs_indent) {
      for (let i = 0; i < this._indent; i++) {
        this.buf += "  ";
      }
      this.needs_indent = false;
    }
    this.buf += v;
    this.needs_indent = v.endsWith("\n");
  }
  writeln(v: string) {
    this.write(v + "\n");
  }

  indent() {
    this._indent += 1;
  }
  deindent() {
    this._indent -= 1;
  }
}
