/**
 * Consolidate all environment variables in one place to make it
 * easier to mock them in tests. More specifically jest does not
 * understand import.meta so all references must be mocked.
 */
export default {
  NODE_ENV: import.meta.env.NODE_ENV,
  VITE_IS_LOCAL: import.meta.env.VITE_IS_LOCAL,
  VITE_DB_URL: import.meta.env.VITE_DB_URL,
  VITE_GRAPHQL_URL: import.meta.env.VITE_GRAPHQL_URL,
  VITE_DEFAULT_REGION_GROUP: import.meta.env.VITE_DEFAULT_REGION_GROUP,
  VITE_DD_APPLICATION_ID: import.meta.env.VITE_DD_APPLICATION_ID,
  VITE_DD_CLIENT_TOKEN: import.meta.env.VITE_DD_CLIENT_TOKEN,
  VITE_ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
  VITE_APP_VERSION: import.meta.env.VITE_APP_VERSION,
  VITE_COOKIE_DOMAIN: import.meta.env.VITE_COOKIE_DOMAIN,
  VITE_GTM_ID: import.meta.env.VITE_GTM_ID,
  VITE_GA_ID: import.meta.env.VITE_GA_ID,
  VITE_GOOGLE_RECAPTCHA_SITE_KEY: import.meta.env
    .VITE_GOOGLE_RECAPTCHA_SITE_KEY,
  VITE_RECAPTCHA_ENABLED: import.meta.env.VITE_RECAPTCHA_ENABLED,
  VITE_AUTH_URL: import.meta.env.VITE_AUTH_URL,
  VITE_STRIPE_KEY: import.meta.env.VITE_STRIPE_KEY,
  VITE_PAGE_SIZE: import.meta.env.VITE_PAGE_SIZE,
  VITE_DISPLAY_PAGE_SIZE: import.meta.env.VITE_DISPLAY_PAGE_SIZE,
  VITE_FORMSPREE_ENDPOINT_CUSTOM_RG: import.meta.env
    .VITE_FORMSPREE_ENDPOINT_CUSTOM_RG,
  VITE_FORMSPREE_ENDPOINT_VPF_INFO: import.meta.env
    .VITE_FORMSPREE_ENDPOINT_VPF_INFO,
  VITE_FORMSPREE_ENDPOINT_ENTERPRISE_INFO: import.meta.env
    .VITE_FORMSPREE_ENDPOINT_ENTERPRISE_INFO,
  VITE_FRONTDOOR_URL: import.meta.env.VITE_FRONTDOOR_URL,
  VITE_OLD_DASHBOARD_URL: import.meta.env.VITE_OLD_DASHBOARD_URL,
  VITE_NEW_ROLES_PAGE: import.meta.env.VITE_NEW_ROLES_PAGE,
};
