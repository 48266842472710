import { BASE_FDS_URL } from "./common";

export const refreshSession = async () => {
  return fetch(`${BASE_FDS_URL}/session/refresh`, {
    method: "POST",
    credentials: "include",
  });
};

export const deleteSession = async () => {
  return fetch(`${BASE_FDS_URL}/session`, {
    method: "DELETE",
    credentials: "include",
  });
};
