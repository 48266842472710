import { BASE_FDS_URL } from "./common";
import { fetchWithCredentials } from "modules/api/utils/fetch";
import { commonResponseHandler } from "modules/api/frontdoor/common";

export interface CreateDatabaseKeyInput {
  /** The name of the role. */
  role: string;
  /** The name of the key. */
  name?: string;
  /** The path to the database. */
  path: string;
  /** The TTL of the key as an ISO 8601 string. */
  ttl: string;
}

export interface CreateDatabaseKeyOutput {
  /** The secret of the key. */
  secret: string;
  /** The path to the database. */
  path: string;
  /** The name of the key. */
  name: string;
  /** The name of the role. */
  role: string;
}

export interface ListDatabaseInput {
  /**
   * The database path. e.g. us-std/Foo
   * If not provided, all databases will be returned
   */
  path?: string;
  /** Token to fetch next page */
  next_token?: string;
  /** Maximum number of results to return */
  max_results?: number;
  /** The field to sort by */
  sort_by?: "name";
}

export interface ListDatabaseOutput {
  /** List of databases */
  results: Array<Database>;
  /** Token to fetch next page */
  next_token?: string;
}

export interface Database {
  name: string;
  path: string;
  has_children: boolean;
  region_group: string;
}

export const listDatabases = async ({
  path,
  next_token,
  max_results,
  sort_by,
}: ListDatabaseInput): Promise<ListDatabaseOutput> => {
  const url = `${BASE_FDS_URL}/databases`;
  const params = new URLSearchParams(
    getUrlInput({ path, next_token, max_results, sort_by })
  );
  const response = await fetchWithCredentials({
    url: `${url}?${params.toString()}`,
    options: {
      method: "GET",
    },
  });
  return commonResponseHandler<ListDatabaseOutput>({
    response,
    defaultErrorMessage: "Failed to list databases",
  });
};

function getUrlInput({
  path,
  next_token,
  max_results,
  sort_by,
}: ListDatabaseInput) {
  const urlInput: {
    max_results?: string;
    next_token?: string;
    path?: string;
    sort_by?: string;
  } = {};
  if (sort_by) {
    urlInput.sort_by = sort_by;
  }
  if (path) {
    urlInput.path = path;
  }
  if (max_results) {
    urlInput.max_results = max_results.toString(10);
  }
  if (next_token) {
    urlInput.next_token = next_token;
  }
  return urlInput;
}

export const createDatabaseKey = async ({
  role,
  path,
  ttl,
  name,
}: CreateDatabaseKeyInput): Promise<CreateDatabaseKeyOutput> => {
  const url = `${BASE_FDS_URL}/databases/keys`;
  const body = {
    role,
    path,
    ttl,
    name,
  };

  const response = await fetchWithCredentials({
    url,
    options: {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    },
  });

  return commonResponseHandler<CreateDatabaseKeyOutput>({
    response,
    defaultErrorMessage: "Failed to create database key",
  });
};
