import { useNotificationStore } from "modules/stores/NotificationStore";
import { datadogRum } from "@datadog/browser-rum-slim";
import useInvoices from "modules/hooks/useInvoices";
import type { Invoice } from "modules/api/auth-service/billing";
import { Notification } from "components/util/Notification";
import { tx } from "modules/language/translate";
import { useEffect, useState } from "react";
import { Button, Text } from "frontend-components";
import {
  getSessionProperty,
  updateSessionData,
  removeSessionAttribute,
} from "modules/session";
import { usePollInvoicesStore } from "modules/stores/PollInvoicesStore";
import {
  INVOICE_NEEDS_ACTION_STATUSES,
  INVOICE_PAID_STATUSES,
} from "modules/api/auth-service/billing";
import { DateTime } from "luxon";

function invoiceNeedsAction(invoice: Invoice) {
  const lastNotification = getSessionProperty<string>([
    "accountNotifications",
    "invoiceNeedsAction",
    "lastNotification",
  ]);
  const currentDateTime = DateTime.now();
  const notificationDue =
    lastNotification === undefined ||
    currentDateTime.diff(DateTime.fromISO(lastNotification), "days").days >= 3;
  return (
    notificationDue &&
    invoice.url &&
    INVOICE_NEEDS_ACTION_STATUSES.has(invoice.status)
  );
}

function showSuccessNotification(actionTaken: boolean, invoice: Invoice) {
  const lastNotification = getSessionProperty<string>([
    "accountNotifications",
    "invoiceNeedsAction",
    "lastNotification",
  ]);
  const currentDateTime = DateTime.now();
  return (
    INVOICE_PAID_STATUSES.has(invoice.status) &&
    (actionTaken ||
      (lastNotification !== undefined &&
        currentDateTime.diff(DateTime.fromISO(lastNotification), "days").days <
          1))
  );
}

export const useAccountNotifications = () => {
  const {
    data: invoices,
    error: invoiceError,
    isLoading: invoiceIsLoading,
  } = useInvoices(true);

  const [actionTaken, setActionTaken] = useState(false);
  const setPollInvoices = usePollInvoicesStore(
    (state) => state.setPollInvoices
  );

  const putNotification = useNotificationStore((state) => state.put);
  const removeNotification = useNotificationStore((state) => state.remove);

  useEffect(() => {
    if (!invoiceError && !invoiceIsLoading) {
      if (invoices && invoices.length > 0) {
        const lastInvoice = invoices[0];
        if (invoiceNeedsAction(lastInvoice)) {
          putNotification({
            asComponent: () => (
              <Notification
                icon="fa-circle-exclamation"
                message={tx("billing.invoices.requires_action")}
                theme="error"
                onClose={() => {
                  removeNotification();
                  datadogRum?.addAction("invoiceActionDismissed", {
                    invoiceStatus: lastInvoice.status,
                  });
                }}
                additionalAction={
                  <Button
                    id="overdue-invoice-button"
                    size="sm"
                    theme="danger"
                    onClick={() => {
                      setPollInvoices(true);
                      setActionTaken(true);
                      datadogRum?.addAction("invoiceActionTaken", {
                        invoiceStatus: lastInvoice.status,
                      });
                      window.open(lastInvoice.url, "_blank");
                    }}
                  >
                    <Text theme="inherit" size="md">
                      {" "}
                      {tx("billing.invoices.actions.resolve")}{" "}
                    </Text>
                  </Button>
                }
              />
            ),
          });
          updateSessionData({
            accountNotifications: {
              invoiceNeedsAction: {
                lastNotification: new Date().toISOString(),
              },
            },
          });
          datadogRum?.addAction("invoiceNeedsAction", {
            invoiceStatus: lastInvoice.status,
          });
        } else if (showSuccessNotification(actionTaken, lastInvoice)) {
          // only put a notification if the invoice was unpaid and now is paid
          setActionTaken(false);
          setPollInvoices(false);
          putNotification({
            asComponent: () => (
              <Notification
                icon="fa-timer"
                message={tx("billing.invoices.payment_success")}
                theme="success"
                onClose={() => {
                  removeNotification();
                  removeSessionAttribute("accountNotifications");
                }}
              />
            ),
          });
          datadogRum?.addAction("invoiceActionSuccess", {
            invoiceStatus: lastInvoice.status,
          });
        }
      }
    }
  }, [invoices]);

  // turn off polling on unmount
  useEffect(() => {
    return () => {
      setPollInvoices(false);
    };
  }, []);
};
