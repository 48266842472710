import { fql, QueryValueObject } from "fauna";
import dedent from "dedent";
import { ROLE, SystemCollections } from ".";
import { FaunaAPI } from "..";
import type { QueryForDisplay } from ".";
import { QueryAccessors } from "../accessors";
import { FqlIdentifier, isLegacyFunctionBody } from "./helperFunctions";
import { BaseDoc, NamedResource, schemaItemUrlBuilder } from "../resource";

/**
 * If a Role has a predicate that is a v4 lambda, it is not v10
 * compatible.  This means we can't open a schema editor for this entity
 * and need to direct the user to the v4 dashboard.
 */
function roleNotV10Compatible(role: Role): boolean {
  return (
    roleMembershipNotV10compatible(role) || rolePrivilegeNotV10Compatible(role)
  );
}

function roleMembershipNotV10compatible(role: Role): boolean {
  // membership can be an array
  if (role?.membership instanceof Array) {
    return role.membership.some((m: Membership) =>
      membershipNotV10Compatible(m)
    );
  }

  // or a single item
  if (role?.membership) {
    return membershipNotV10Compatible(role.membership);
  }

  return false;
}

function membershipNotV10Compatible(membership: Membership): boolean {
  // v4 will use Refs that aren't currently supported in v10 schema.
  // for roles made with v10 the resource will be a string.
  if (membership?.resource && !(typeof membership.resource === "string")) {
    return true;
  }

  if (membership?.predicate) {
    return isLegacyFunctionBody(membership.predicate);
  } else {
    return false;
  }
}

function rolePrivilegeNotV10Compatible(role: Role): boolean {
  // privileges can be an array of privileges
  if (role?.privileges instanceof Array) {
    return role.privileges.some((privilege: Privilege) => {
      return privilegeNotV10Compatible(privilege);
    });
  }

  // or a single privilege object
  if (role?.privileges) {
    return privilegeNotV10Compatible(role.privileges);
  }

  return false;
}

function privilegeNotV10Compatible(privilege: Privilege): boolean {
  // v4 will use Refs that aren't currently supported in v10 schema.
  // for roles made with v10 the resource will be a string.
  if (privilege?.resource && !(typeof privilege.resource === "string")) {
    return true;
  }

  if (
    typeof privilege?.resource === "string" &&
    privilege?.resource.includes("legacy index")
  ) {
    return true;
  }

  if (privilege?.actions) {
    const possiblePredicates = Object.values(privilege.actions);
    return possiblePredicates.some((pred) => isLegacyFunctionBody(pred));
  }

  return false;
}

export enum BuiltInRole {
  Admin = "admin",
  Server = "server",
  ServerReadonly = "server-readonly",
  Client = "client",
}

export const BUILT_IN_ROLES = [
  { id: BuiltInRole.Admin, name: "admin" },
  { id: BuiltInRole.Server, name: "server" },
  { id: BuiltInRole.ServerReadonly, name: "server (read-only)" },
  { id: BuiltInRole.Client, name: "client", hide: true },
];

export const DISPLAY_ROLES = BUILT_IN_ROLES.filter(({ hide }) => !hide);

export function isBuiltInRole(roleName: string) {
  return BUILT_IN_ROLES.some(({ id }) => id === roleName);
}

export function isRole(role: string | Role): role is Role {
  return (role as Role).name !== undefined;
}

export type Action =
  | "create"
  | "create_with_id"
  | "delete"
  | "read"
  | "write"
  | "history_read"
  | "call";

export const CollectionActions: Action[] = [
  "create",
  "delete",
  "read",
  "write",
  "create_with_id",
  "history_read",
];

export const FunctionActions: Action[] = ["call"];

export const MembershipLambdaTemplate = dedent(`
  // Edit the template below to add a predicate.
  (user) => false
`);

export const PrivilegeLambdaTemplates: Record<Action, string> = {
  create: dedent(`
    // Edit the template below to add a predicate.
    (doc) => false
  `),
  delete: dedent(`
    // Edit the template below to add a predicate.
    (doc) => false
  `),
  read: dedent(`
    // Edit the template below to add a predicate.
    (doc) => false
    `),
  write: dedent(`
    // Edit the template below to add a predicate.
    (oldDoc, newDoc) => false
  `),
  history_read: dedent(`
    // Edit the template below to add a predicate.
    (doc) => false
  `),
  create_with_id: dedent(`
    // Edit the template below to add a predicate.
    (doc) => false
  `),
  call: dedent(`
    // Edit the template below to add a predicate.
    (args) => false
  `),
};

export type Actions = { [key in Action]?: boolean | string };

export type Privilege = {
  resource: string;
  actions: Actions;
  resourceName?: string; // This field is added manually by the getRole function
  resourceAlias?: string; // This field is added manually by the getRole function
  coll?: "Collection" | "Function"; // This field is added manually by the getRole function
};

export type Membership = {
  resource: string;
  predicate?: string;
  resourceName?: string; // This field is added manually by the getRole function
  resourceAlias?: string; // This field is added manually by the getRole function
};

export type Role<MetaData extends QueryValueObject = {}> = BaseDoc<MetaData> & {
  name: string;
  privileges: Privilege[];
  membership?: Membership[];
  data?: any;
};

export type V4PredicateRole = {
  predicate: string;
  role: Role;
};

export type CreateRole = {
  name: string;
};

export function createRoleQuery({ name }: CreateRole): QueryForDisplay {
  return {
    query: fql`Role.create({ name: ${name} })`,
    display: `Role.create({ name: "${name}" })`,
  };
}

export type GetRole = {
  name: string;
};

export function getRoleQuery({ name }: GetRole): QueryForDisplay {
  return {
    query: getRole(name),
    display: `Role.byName("${name}")`,
  };
}

/**
 * A function that looks up a role and associated privilege/membership metadata.
 * @param name
 * @returns Role with typed privileges
 */
function getRole(name: string) {
  return fql`
    let role = Role.byName(${name})!

    let augmentedPrivileges = (role.privileges ?? []).map((privilege) => {
      // Some v4 privilege resources are not strings, for instance v4 indexes are
      //  represented as [legacy index <index name>]. Trying to call Collection.byName
      //  without casting to a string will throw an error.
      let name = Object.toString(privilege.resource.name ?? privilege.resource)
      let def = FQL.Schema.defForIdentifier(name)
      if (${SystemCollections}.includes(name)) {
        Object.assign(privilege, { coll: "Collection", resourceName: name, resourceAlias: null })
      } else if (def == null) {
        privilege
      } else if (def.coll == Function) {
        Object.assign(privilege, { coll: "Function", resourceName: def.name, resourceAlias: def.alias })
      } else if (def.coll == Collection) {
        Object.assign(privilege, { coll: "Collection", resourceName: def.name, resourceAlias: def.alias })
      } else {
        Object.assign(privilege, { resourceName: def.name, resourceAlias: def.alias })
      }
    })

    let augmentedMembership = (role.membership ?? []).map((membership) => {
      let name = Object.toString(membership.resource.name ?? membership.resource)
      let def = FQL.Schema.defForIdentifier(name)
      if (${SystemCollections}.includes(name)) {
        Object.assign(membership, { coll: "Collection", resourceName: name, resourceAlias: null })
      } else if (def == null) {
        membership
      } else {
        Object.assign(membership, { resourceName: def.name, resourceAlias: def.alias })
      }
    })

    Object.assign(role, { privileges: augmentedPrivileges, membership: augmentedMembership })
  `;
}

export type RoleCreate = {
  name: string;
  privileges?: Privilege[];
  membership?: Membership[];
  data?: any;
};

export type RoleUpdate = {
  name?: string;
  privileges?: Privilege[];
  membership?: Membership[];
  data?: any;
};

export class RoleAccessors<
  MetaData extends QueryValueObject = {}
> extends QueryAccessors<RoleCreate, RoleUpdate, Role<MetaData>> {
  constructor(api: FaunaAPI) {
    super(api, "Role");
  }
}

export class Roles<
  MetaData extends QueryValueObject = {}
> extends NamedResource<RoleAccessors> {
  constructor(api: FaunaAPI) {
    const accessors = new QueryAccessors<
      RoleCreate,
      RoleUpdate,
      Role<MetaData>
    >(api, "Role");
    super(api, FqlIdentifier("Role"), accessors, ROLE);
    this.path = "roles";
    this.retrieveQ = getRole;
    this.schemaItemUrl = schemaItemUrlBuilder(this.path);
    this.notV10Compatible = roleNotV10Compatible;
    this.isResourceIdMutable = true;
    this.shellTabIconClass = "fa-user-shield";
  }
}
