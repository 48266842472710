import { COLLECTION, QueryForDisplay } from ".";
import { FqlIdentifier } from "./helperFunctions";
import { fql, type QueryValueObject } from "fauna";
import { FaunaAPI } from "..";
import {
  BaseDoc,
  NamedResource,
  ResourceAccessor,
  schemaItemUrlBuilder,
} from "../resource";
import { FqlIdentifierRegex } from "./helperFunctions";
import { QueryAccessors } from "../accessors";
import { display } from "../display";

export type Term = {
  field: string;
};

export type Value = {
  field: string;
  order: "asc" | "desc";
};

export type Index = {
  terms?: Array<Term>;
  values?: Array<Value>;
  queryable?: boolean;
  status?: string;
  partitions?: number;
};

export type Indexes = { [key: string]: Index };

export type Constraint = {
  unique: Array<any>;
  status?: string;
};

export type Collection<MetaData extends QueryValueObject = {}> =
  BaseDoc<MetaData> & {
    name: string;
    history_days?: number;
    ttl_days?: number;
    indexes?: Indexes;
    constraints?: Array<Constraint>;
    alias?: string;
  };

export type CollectionCreate = {
  name: string;
  history_days?: number | null;
  ttl_days?: number | null;
  indexes?: Indexes;
  constraints?: Array<Constraint>;
  data?: QueryValueObject;
};
export type CollectionUpdate = {
  name?: string;
  history_days?: number | null;
  ttl_days?: number | null;
  indexes?: Indexes;
  constraints?: Array<Constraint>;
  data?: QueryValueObject;
};

export type CreateIndex = {
  indexName: string;
  collectionName: string;
  indexDefinition: Index | null;
};

export type UpdateIndex = CreateIndex & {
  oldIndexName?: string;
};

export const SystemCollections = [
  "AccessProvider",
  "Collection",
  "Credential",
  "Database",
  "Function",
  "Key",
  "Role",
  "Token",
];

const collectionNeedsAlias = (collectionEntity: Collection) => {
  return (
    (collectionEntity.name.match(FqlIdentifierRegex) === null ||
      SystemCollections.includes(collectionEntity.name)) &&
    collectionEntity.alias === undefined
  );
};

export function createOrUpdateIndexQuery({
  indexName,
  collectionName,
  indexDefinition,
}: UpdateIndex): QueryForDisplay {
  const updatedIndexDefinition = { [indexName]: indexDefinition };
  return {
    query: fql`
${FqlIdentifier(collectionName)}.definition.update({
  indexes: ${updatedIndexDefinition}
}) `,
    display: `
${collectionName}.definition.update({
  indexes: ${display(updatedIndexDefinition, 1)}
})`,
  };
}

export class CollectionAccessors<
  MetaData extends QueryValueObject = {}
> extends QueryAccessors<
  CollectionCreate,
  CollectionUpdate,
  Collection<MetaData>
> {
  createOrUpdateIndex: ResourceAccessor<UpdateIndex, Collection<MetaData>>;

  constructor(api: FaunaAPI) {
    super(api, "Collection");

    this.createOrUpdateIndex = this.buildAccessor<
      UpdateIndex,
      Collection<MetaData>
    >(createOrUpdateIndexQuery, this.retrieveQ);
  }
}

export class Collections<
  MetaData extends QueryValueObject = {}
> extends NamedResource<CollectionAccessors> {
  constructor(api: FaunaAPI) {
    const accessors = new CollectionAccessors<MetaData>(api);

    super(api, FqlIdentifier("Collection"), accessors, COLLECTION);
    this.path = "collections";
    this.schemaItemUrl = schemaItemUrlBuilder(this.path);
    this.needsAlias = collectionNeedsAlias;
    this.isResourceIdMutable = true;
    this.reservedWordsRegexes = [/\n\s\scoll:.*\n/, /\n\s\sts:.*\n/];
    this.shellTabIconClass = "fa-table";
  }
}
