import { Card, Icon, Layout, Link, Text } from "frontend-components";

export const AnnouncementBanner = ({
  rounded = false,
}: {
  rounded?: boolean;
}) => {
  return (
    <Card padding="md" theme="error" rounded={rounded} shadow="none">
      <Layout flow="left-right" gap="xl" alignItems="center">
        <Icon
          iconClassName={"fa-circle-exclamation"}
          theme="primary"
          size="xl"
        />
        <Text theme="primary" size="lg" style={{ maxWidth: "100%" }}>
          The Fauna service will be ending on <strong>May 30, 2025</strong>. For
          more information, read the{" "}
          <Link
            href="https://fauna.com/blog/the-future-of-fauna"
            external={true}
          >
            <Text theme="inherit" size="lg">
              announcement
            </Text>
          </Link>{" "}
          and the{" "}
          <Link
            href="https://docs.fauna.com/fauna/current/eol-faq"
            external={true}
          >
            <Text theme="inherit" size="lg">
              FAQ
            </Text>
          </Link>
          .
        </Text>
      </Layout>
    </Card>
  );
};
