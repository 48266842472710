import env from "src/env";

let dictionary: Record<string, any>;

// Accepts optional array of string values to substiture into {0} {1} hotspots
const get = (path: string, params?: string[] | null | undefined): string => {
  if (!path) {
    throw new Error("You should pass text as string");
  }

  return replaceNewline(replaceParams(textFromPath(path), params));
};

const textFromPath = (path: string): string => {
  const paths = path.split(".");

  const text = paths.reduce((object, param) => {
    const textValueOrTextPath = object?.[param] || path;
    return textValueOrTextPath;
  }, dictionary);

  if (text === paths && env.NODE_ENV !== "production") {
    console.warn(`Translation for ${text} was not found.`);
  }

  return text.toString();
};

const replaceParams = (
  text: string,
  params: string[] | null | undefined
): string => {
  if (params) {
    return params.reduce((text, param, index) => {
      return text.replace(`{${index}}`, param);
    }, text);
  } else {
    return text;
  }
};

const replaceNewline = (text: string): string => {
  return text.replace(/\\n/g, String.fromCharCode(13));
};

export const tx = get;

export const setDictionary = (newDictionary: Record<string, any>): void => {
  dictionary = newDictionary;
};
