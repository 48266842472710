import env from "src/env";
import { FetchError } from "modules/api/utils/fetch";

export const bearerToken = (secret: string) => "Bearer " + secret;
export const BASE_FDS_URL = `${env.VITE_FRONTDOOR_URL}/api/v1`;

/**
 * The shape of an error returned by the frontdoor service.
 */
export interface FrontdoorError extends Error {
  code?: string;
  reason?: string;
}

/**
 * Checks if the given object is a {@link FrontdoorError}.
 *
 * @param maybeError The object to check.
 * @param response The response from the server. The response is used to make sure
 *  it's not a legit response from the server that just happens to have the same
 *  shape.
 * @returns True if the object is a {@link FrontdoorError}, false otherwise.
 */
export const isFrontdoorError = (
  maybeError: unknown,
  response: Response
): maybeError is FrontdoorError => {
  return (
    maybeError !== null &&
    typeof maybeError === "object" &&
    ("code" in maybeError || "reason" in maybeError) &&
    !response.ok
  );
};

// Options for commonResponseHandler
type CommonResponseHandlerOptions = {
  response: Response;
  expectResponseData?: boolean;
  defaultErrorMessage?: string;
};

/**
 * Overload when expectResponseData is true.
 * The return type should always be the expected type if expectResponseData is true.
 */
export async function commonResponseHandler<T>(
  opts: CommonResponseHandlerOptions & { expectResponseData?: true }
): Promise<T>;

/**
 * Overload when expectResponseData is false.
 * The return type should always be undefined if expectResponseData is false.
 */
export async function commonResponseHandler(
  opts: CommonResponseHandlerOptions & { expectResponseData: false }
): Promise<void>;

/**
 * Encapsulates error handling and response status checking for frontdoor
 * requests.
 *
 * @param options.response The response from the server.
 * @param options.expectResponseData Whether the response data is expected to be undefined.
 * @param options.defaultErrorMessage The default error message to use if we receive an unexpected response.
 * @returns The response data.
 * @throws A {@link FetchError} if we receive an error or the request fails unexpectedly.
 */
export async function commonResponseHandler<T>({
  response,
  expectResponseData = true,
  defaultErrorMessage = "Request failed",
}: CommonResponseHandlerOptions): Promise<T | undefined> {
  let data: T | FrontdoorError | undefined;

  // Unpack the response data.
  try {
    data = await response.json();
  } catch (e) {
    data = undefined;
  }

  // Check if the response is an auth service error.
  if (isFrontdoorError(data, response)) {
    throw new FetchError({
      code: data.code,
      message: data.reason,
      status: response.status,
    });
  } else if ((!data && expectResponseData) || !response.ok) {
    // If we were expecting data but didn't get any, or the response is bad for
    // any other reason, throw an error.
    throw new FetchError({
      status: response.status,
      message: defaultErrorMessage,
    });
  }

  return data;
}
