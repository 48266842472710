import env from "src/env";
import * as cookies from ".";
import { SessionRegion } from "modules/session";

export type OAuthCookieContents = {
  request_id: string;
  regionGroups: Record<string, SessionRegion>;
};

const buildCookieTtl = () => {
  const inTenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);
  return inTenMinutes;
};

const OAUTH_SESSION_COOKIE_NAME = "oauth-session";

const SESSION_COOKIE_CONFIG: cookies.CookieConfig = {
  secure: !(env.VITE_IS_LOCAL == "true"),
  domain: cookies.COOKIE_DOMAIN,
  // Code can only find and fetch the cookie value if running on a component within
  //  the /authorize route tree
  path: "/authorize",
};

const get = (): OAuthCookieContents => cookies.get(OAUTH_SESSION_COOKIE_NAME);

const remove = (): void => {
  cookies.remove(OAUTH_SESSION_COOKIE_NAME, "/authorize");
};

const save = (contents: OAuthCookieContents, ttl?: number | Date): void =>
  cookies.save(OAUTH_SESSION_COOKIE_NAME, contents, {
    ...SESSION_COOKIE_CONFIG,
    // https://github.com/js-cookie/js-cookie/tree/v2.0.4#expires
    // When a number, ttl represents days from now to expire the cookie.
    // Else expire it at the given Date object's representation
    expires: ttl || buildCookieTtl(),
  });

const exists = (): boolean => cookies.exists(OAUTH_SESSION_COOKIE_NAME);

const updateTTL = (ttl: number | Date = 30): void => {
  const cookie = get();
  if (!cookie) return;
  save(cookie, ttl);
};

/**
 * The Region class is right now tightly coupled with the dashboard type session.
 * The oauth2 api call which the dashboard has to make is region-agnostic, and we
 * know that everyone has access to the US region. So instead of refactoring and hoisting
 * the Region class, provide an quick way to fetch the secret for oauth-type sessions and
 * keep it separate from dashboard-type session secrets
 * @param region
 * @returns
 */
const getOAuthSessionRegionSecret = (): string => {
  return get()?.regionGroups["us"]?.secret || "";
};

const OAuthSessionCookie = {
  get,
  remove,
  save,
  exists,
  updateTTL,
  getOAuthSessionRegionSecret,
};

export default OAuthSessionCookie;
