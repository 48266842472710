import React, { useEffect, useState } from "react";
import { UserIdentity, getUserIdentity } from "modules/api/auth-service/authz";
import { Session, getSession, updateSessionData } from "modules/session";
import useSWR, { KeyedMutator } from "swr";
import { Plan, Role } from "modules/api/auth-service/permissions";
import { useLocation } from "react-router-dom";

type UserIdentityContext = UserIdentity & {
  revalidateUserIdentity?: KeyedMutator<UserIdentity> | undefined;
  loading?: boolean;
  pollUserIdentity: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialState: UserIdentityContext = {
  role: Role.owner,
  plan: Plan.free,
  planSynced: true,
  plan_display_name: "Loading...",
  plan_monthly_price: 0,
  limits: {},
  view_controls: { "*": [] },
  feature_flags: {},
  is_plan_deprecated: false,
  id: "",
  loading: true,
  pollUserIdentity: () => {},
  userRegionGroups: {},
  eligible_for_trial: true,
  otp_enabled: false,
  pending_email: "",
};

export const UserIdentityContext =
  React.createContext<UserIdentityContext>(initialState);

/**
 * Combine the initial session data with the user identity data from the auth service
 * which will be polled every 10 seconds.
 * @param props children which will have access to userIdentity context
 * @returns react context provider
 */
export const UserIdentityProvider: React.FC<{ children: any }> = (props) => {
  const [userIdentity, setUserIdentity] = useState<UserIdentity>(initialState);
  const [shouldPollUserIdentity, pollUserIdentity] = useState<boolean>(false);
  const [loadingIdentity, setLoadingIdentity] = useState<boolean>(true);
  const location = useLocation();

  // We should check for backend plan updates when the user navigates to a new page
  //  and when they submit a plan upgrade request. Because this is a shared context,
  //  all pages within this route won't cause this component to re-mount so we must
  //  trigger manually using location changes.
  useEffect(() => {
    if (mutate && !loadingIdentity) {
      setLoadingIdentity(true);
      mutate();
    }
  }, [location]);

  // Polling should only be kicked off manually by components that need it (PlanChangeProgress.tsx)
  //  This ensures however that polling stops if planSynced is true, regardless of which component
  //  started the polling via `polluserIdentity(true)`
  useEffect(() => {
    if (userIdentity.planSynced) {
      pollUserIdentity(false);
    }
  }, [userIdentity]);

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    "user_identity",
    getUserIdentity,
    { refreshInterval: shouldPollUserIdentity ? 5000 : undefined }
  );

  const session = getSession();

  useEffect(() => {
    if (data && !error && !isLoading && !isValidating && session) {
      // Prevent component re-renders if the data hasn't changed
      //  Every update to userIdentity variable will trigger a re-render
      //  for components and pages within the ProtectedRoute which depends on
      //  this Context Provider
      if (JSON.stringify(data) !== JSON.stringify(userIdentity)) {
        setUserIdentity(data);
        // Keep local session object in sync with out-of-band plan/role/limit updates
        updateSessionUserIdentity(session, data);
      }
    }
    if (error) {
      // If we fail to fetch updated identity info durring polling, don't break the page,
      //  but display permissions according to the last good state which would
      //  be set when logging in and/or navigating to a new page.
      console.error(
        "Failed to fetch user identity, using last good state",
        error
      );
    }
    if (!isLoading && !isValidating) {
      setLoadingIdentity(false);
    }
  }, [data, error, isLoading, isValidating]);

  return (
    <UserIdentityContext.Provider
      value={{
        ...userIdentity,
        revalidateUserIdentity: mutate,
        loading: loadingIdentity,
        pollUserIdentity,
      }}
    >
      {props.children}
    </UserIdentityContext.Provider>
  );
};

const updateSessionUserIdentity = (
  session: Session,
  userIdentity: UserIdentity
) => {
  const sessionDataAttributesToUpdate: Partial<Session> = {
    user: {
      ...session.user,
      role: userIdentity.role,
      plan: userIdentity.plan,
      plan_display_name: userIdentity.plan_display_name,
      planSynced: userIdentity.planSynced,
      limits: userIdentity.limits,
      plan_monthly_price: userIdentity.plan_monthly_price,
      pending_email: userIdentity.pending_email,
      otp_enabled: userIdentity.otp_enabled,
    },
    view_controls: userIdentity.view_controls,
    feature_flags: userIdentity.feature_flags,
    userRegionGroups: userIdentity.userRegionGroups,
  };
  if (
    userIdentity.eligible_for_trial !== undefined &&
    sessionDataAttributesToUpdate?.user !== undefined
  ) {
    sessionDataAttributesToUpdate.user.eligible_for_trial =
      userIdentity.eligible_for_trial;
  }
  updateSessionData(sessionDataAttributesToUpdate);
};
