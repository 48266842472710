import { useMemo } from "react";
import { Params } from "react-router-dom";
import { FetchError } from "modules/api/utils/fetch";
import { SWRCacheState } from "../modules/api/fauna/hooks";
import { useFauna } from "../modules/api/fauna/hooks/useFauna";
import type { FaunaURLParams } from "../modules/hooks/useFaunaURL";
import { useInviteByToken } from "../modules/hooks/useInvites";
import usePermissions from "modules/hooks/usePermissions";
import {
  PermissionDomain,
  PermissionLevel,
} from "modules/api/auth-service/permissions";

/*
  ROUTE VALIDATION FUNCTIONS

  This file serves as a place to store different route validators. Because the app
  uses client-side rendering, we cannot validate a dynamic URL points to a valid resource
  without making an API call. Different pages in the app request different resources, so the
  API call used to validate if a URL points to a valid resource may differ.
  1. Choose which API call or async function makes most sense to validate a given
    URL route (see the routes in routes.ts).
  2. Create a new hook (e.g. useDBRouteValidation) that accepts the url variables
  3. Use the async function's return value to determine the proper status and messages to
    send back to the Routes.tsx component
  4. Once a new validation hook is created, apply it to any route in routes.ts.
    Because these validators exist to validate the URL path is correct, they will likely only be
    used on GET routes (e.g. won't need to validate a route for creating a new db)

    TODO:
    * deduce from a route validator if the session is still valid, if not, logout/prompt reauth
      (could get stale session while on any page)
    *
*/

export enum RouteValidationStatus {
  NOT_FOUND,
  PERMISSION_DENIED,
  INTERNAL_ERROR,
  SUCCESS,
  PENDING,
}

export type RouteValidationResponse = {
  title: string;
  status: RouteValidationStatus;
  message: string;
  suggestion?: string;
  failureReasons?: string[];
};

export type Validator = (
  urlParams: Readonly<Params<string>> | FaunaURLParams
) => RouteValidationResponse;

export const defaultValidationResponse: RouteValidationResponse = {
  title: "Whoops!",
  status: RouteValidationStatus.NOT_FOUND,
  message: "Failed to access resource",
  failureReasons: ["URL may be incorrect"],
  suggestion: "You may attempt a page refresh or return to the dashboard.",
};

/**
 * Validate a db exists based on url params. useDatabaseDetails calls useDatabases and useRestores.
 *  useDatabases returns an error but no status code. Thus the !status check. useRestores does
 *  return a status code. useDatabaseDetails' error object may the error response from one or the
 *  other so both cases must be handled.
 * @param urlMatchParams the url path including dpPath and region
 * @returns RouteValidationResult
 */
// TODO: remove, unused
export const useDBRouteValidation: Validator = (
  urlMatchParams
): RouteValidationResponse => {
  const { regionPrefix, path } = urlMatchParams;

  if (!path) {
    return {
      ...defaultValidationResponse,
      status: RouteValidationStatus.NOT_FOUND,
    };
  }

  const {
    error,
    data,
    state: isLoading,
  } = useFauna().useCurrentDatabase().api.check();

  const validationResponse = useMemo(() => {
    let { title, status, message, suggestion, failureReasons } = {
      ...defaultValidationResponse,
    };
    // TODO: check if a copy/restore is in progress and display the unvailable 404 wording
    //  in the figma design: https://www.figma.com/file/GRERj2zC6g7KcdDq82oDi6/Design?node-id=1243%3A38595
    if (error) {
      const { status: errorStatus } = JSON.parse(JSON.stringify(error));
      if (!errorStatus || errorStatus === 404) {
        status = RouteValidationStatus.NOT_FOUND;
        message = `The database ${path} in region ${regionPrefix} does not exist.`;
        failureReasons = [
          "It has been deleted",
          "The URL is incorrect",
          "A copy or restore is in progress",
        ];
        suggestion =
          "You can return to the dashboard to try and locate the database.";
      } else if (errorStatus === 500) {
        status = RouteValidationStatus.INTERNAL_ERROR;
      }
    } else if ((!error && !data) || isLoading !== SWRCacheState.READY) {
      status = RouteValidationStatus.PENDING;
    } else {
      status = RouteValidationStatus.SUCCESS;
    }
    return {
      title,
      status,
      message,
      suggestion,
      failureReasons,
    };
  }, [error, data, isLoading, path, regionPrefix]);
  return validationResponse;
};

/**
 * Validate that the user has permissions to view billing pages.
 * @returns RouteValidationResult
 */
export const useBillingRouteValidation: Validator =
  (): RouteValidationResponse => {
    const {
      loading: billingReadPermissionLoading,
      hasPermission: hasBillingReadPermission,
    } = usePermissions(PermissionDomain.BILLING, PermissionLevel.read);

    let { title, status, message, suggestion } = {
      ...defaultValidationResponse,
    };

    if (billingReadPermissionLoading) {
      status = RouteValidationStatus.PENDING;
    } else if (!hasBillingReadPermission) {
      title = "Permission Denied";
      status = RouteValidationStatus.PERMISSION_DENIED;
      message = "You do not have permission to view this page.";
      suggestion = "Please contact your account administrator.";
    } else {
      status = RouteValidationStatus.SUCCESS;
    }

    return {
      title,
      status,
      message,
      suggestion,
    };
  };

/**
 * Validate that the user has permissions to read Team Management pages.
 * @returns RouteValidationResult
 */
export const useReadAccountRouteValidation: Validator =
  (): RouteValidationResponse => {
    const {
      loading: accountReadPermissionLoading,
      hasPermission: hasAccountReadPermission,
    } = usePermissions(PermissionDomain.ACCOUNT, PermissionLevel.read);

    let { title, status, message, suggestion } = {
      ...defaultValidationResponse,
    };

    if (accountReadPermissionLoading) {
      status = RouteValidationStatus.PENDING;
    } else if (!hasAccountReadPermission) {
      title = "Permission Denied";
      status = RouteValidationStatus.PERMISSION_DENIED;
      message = "You do not have permission to view this page.";
      suggestion = "Please contact your account administrator.";
    } else {
      status = RouteValidationStatus.SUCCESS;
    }

    return {
      title,
      status,
      message,
      suggestion,
    };
  };

/**
 * Check that a valid token was provided in the URL when visiting the Accept Invite page
 * @param urlMatchParams url params
 * @returns RouteValidationResult
 */
export const useAcceptInviteRouteValidation: Validator = (
  urlMatchParams
): RouteValidationResponse => {
  const { inviteToken } = urlMatchParams;

  const invite = useInviteByToken(inviteToken ?? "");

  const validationResponse = useMemo(() => {
    let { title, status, message, suggestion } = {
      ...defaultValidationResponse,
    };

    if (invite.isLoading) {
      status = RouteValidationStatus.PENDING;
    } else if (invite.error) {
      console.error(invite.error);
      if (invite.error instanceof FetchError) {
        title = invite.error.message;
      } else {
        title = "Unkown Error";
      }
      status = RouteValidationStatus.NOT_FOUND;
      message = "Your invite token is either invalid or expired.";
      suggestion =
        "Invites expire after 24 hours, or once they have been used. Please ask your team admin to send you another invite.";
    } else {
      status = RouteValidationStatus.SUCCESS;
    }

    return {
      title,
      status,
      message,
      suggestion,
    };
  }, [invite]);

  return validationResponse;
};
