import React, { useState } from "react";
import { tx } from "modules/language/translate";

const SUCCESS_TOAST_TIMEOUT = 3000;

type ToastContextState = {
  makeToast: (props: LocalToastType) => void;
  makeSuccessToast: (description: string, timeoutMs?: number) => void;
};
import { Toast, makeToast as makeFaunaToast } from "frontend-components";
interface LocalToastType extends React.ComponentProps<typeof Toast> {
  timeoutMs?: number;
}

const initialState = {
  makeToast: () => {},
  makeSuccessToast: () => {},
};

const defaultToastProps: LocalToastType = {
  theme: "success",
  title: "",
  description: "",
  position: "top-left",
};

export const ToastContext =
  React.createContext<ToastContextState>(initialState);

export const ToastProvider: React.FC<any> = (props) => {
  const [_toastProps, _setToastProps] =
    useState<LocalToastType>(defaultToastProps);
  const [_toastVisible, _setToastVisible] = useState(false);

  /**
   * Take in the same props that the Toast does in the components library and pass
   * them through to a Toast which we render above the children.
   * Toast component's makeToast returns a function which we can run to stop
   * the automated visibility timeout. If you don't specify a timeoutMs, we
   * will leave the toast visible until dismiss is pressed.
   * TODO: allow for multiple toasts on the same page with some array in state
   * that tracks the different toasts and their properties. In the render method
   * step through the array to build the toast components.
   * @param props
   */
  const makeToast = (props: LocalToastType) => {
    _setToastProps(props);
    makeFaunaToast(_setToastVisible, props.timeoutMs || "max");
  };

  const makeSuccessToast = (description: string, timeoutMs?: number) => {
    makeToast({
      title: tx("attributes.success"),
      description,
      theme: "success",
      timeoutMs: timeoutMs ?? SUCCESS_TOAST_TIMEOUT,
    });
  };

  const state = {
    makeToast,
    makeSuccessToast,
  };

  return (
    <ToastContext.Provider value={state}>
      <Toast
        theme={_toastProps.theme}
        title={_toastProps.title}
        description={_toastProps.description}
        actions={(_toastProps.actions || []).concat([
          { text: "dismiss", onClick: () => _setToastVisible(false) },
        ])}
        visible={_toastVisible}
        position={_toastProps.position}
      />
      {props.children}
    </ToastContext.Provider>
  );
};
