import { Region } from "modules/region";
import { getSession } from "../../session/index";

const getHeaders = () => {
  const session = getSession();

  if (session?.secret !== undefined) {
    return {
      secret: session?.secret,
    };
  }

  const region = Region.default;

  let headers: Record<string, string> = {};
  if (region?.key) headers["secret"] = region?.key;
  if (region?.regionPrefix) headers["region"] = region?.regionPrefix;

  return headers;
};

export default getHeaders;
